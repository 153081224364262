import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import AuthContext from '../../../../../context/Auth/auth';
import { getGoogleMapsApiKey } from '../../../../../config';
import {
  setMapOptions,
  createMarker,
  createCircle,
  clearLastMarker,
  createExistingZoneMarkers,
  isLocationInExistingZone,
  locationBoundsChecker,
} from '../../../../../helpers/MapHelper';

function ZMap({
  clickCallback,
  mapCenter,
  existingLocations,
  currentMarker,
  lat,
  lng,
  radius,
  color,
}) {
  const context = useContext(AuthContext);
  const ref = useRef();

  const [map, setMap] = useState();

  const [activeMarker, setActiveMarker] = useState({});
  const [previousActiveMarker, setPreviousActiveMarker] = useState({});

  const [existingZoneMarkers, setExistingZoneMarkers] = useState([]); // eslint-disable-line no-unused-vars
  const [existingZoneCircles, setExistingZoneCircles] = useState([]);

  const [sessionRadius, setSessionRadius] = useState(radius);
  const [sessionColor, setSessionColor] = useState(color);

  //#region [rgba(0, 205, 30, 0.1)] Map Component Load
  const onLoad = () => {
    if (window.google) {
      const options = setMapOptions(
        currentMarker,
        mapCenter.lat,
        mapCenter.lng
      );

      let zoneCMSMap = new window.google.maps.Map(ref.current, options);
      zoneCMSMap.setTilt(0);
      zoneCMSMap.addListener('click', event => {
        handleClickEventForZone(event);
      });

      const parkPath = new window.google.maps.Polyline({
        path: context.tbProps.parkBoundary,
        geodesic: true,
        strokeColor: '#FFFFFF',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      parkPath.setMap(zoneCMSMap);

      setMap(zoneCMSMap);

      // All zone locations for selected park get rendered on map.
      if (existingLocations) {
        const [markers, circles] = createExistingZoneMarkers(
          zoneCMSMap,
          existingLocations,
          currentMarker.ZoneId
        );

        setExistingZoneMarkers(markers);
        setExistingZoneCircles(circles);
      }

      // Editing a location should see previous location.
      if (currentMarker && currentMarker.Latitude !== 0) {
        const lat = currentMarker.Latitude;
        const lng = currentMarker.Longitude;

        setActiveMarker({ lat, lng });
      }
    }
  };
  //#endregion

  //#region [rgba(231,76,60,0.1)] Click Events
  const handleClickEventForZone = event => {
    if (clickCallback) {
      const result = {
        lat: parseFloat(event.latLng.lat().toFixed(7)),
        lng: parseFloat(event.latLng.lng().toFixed(7)),
      };

      const inExistingLocation = isLocationInExistingZone(
        result.lat,
        result.lng,
        existingZoneCircles
      );

      locationBoundsChecker(result, sessionRadius, existingLocations);

      if (!inExistingLocation) {
        clickCallback(result);
        setActiveMarker(result);
      }
    }
  };
  //#endregion

  //#region [rgba(52,152,219,0.1)] useEffects
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement(`script`);

      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` + getGoogleMapsApiKey();
      document.head.append(script);
      script.addEventListener(`load`, onLoad);

      return () => script.removeEventListener(`load`, onLoad);
    } else {
      onLoad();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeMarker && window.google) {
      clearLastMarker(previousActiveMarker);

      const m = createMarker(map, activeMarker.lat, activeMarker.lng);
      m.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeWeight: 3,
        strokeColor: 'black',
        fillColor: 'black',
      });

      // Radius bug
      let r = radius;
      if (!(r > 0)) {
        r = 20;
      }

      const circle = createCircle(map, r, sessionColor);
      circle.bindTo('center', m, 'position');
      setPreviousActiveMarker({ marker: m, circle: circle });
    }
  }, [activeMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (radius && previousActiveMarker.circle) {
      previousActiveMarker.circle.setRadius(radius);
      setSessionRadius(radius);
    }
  }, [radius]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (color && previousActiveMarker.circle) {
      previousActiveMarker.circle.setOptions({ fillColor: color });
      setSessionColor(color);
    }
  }, [color]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   if (!isEmpty(mapCenter)) {
  //     const options = setMapOptions(null, mapCenter.lat, mapCenter.lng);

  //     let zoneCMSMap = new window.google.maps.Map(ref.current, options);

  //     setMap(zoneCMSMap);
  //   }
  // }, [mapCenter]);

  useEffect(() => {
    if (lat !== undefined && lng !== undefined) {
      const result = {
        lat: parseFloat(lat.toFixed(7)),
        lng: parseFloat(lng.toFixed(7)),
      };

      const inExistingLocation = isLocationInExistingZone(
        result.lat,
        result.lng,
        existingZoneCircles
      );

      locationBoundsChecker(result, sessionRadius, existingLocations);

      if (!inExistingLocation) {
        clickCallback(result);
        setActiveMarker(result);
      }
    }
  }, [lat, lng]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion

  return (
    <>
      <div
        style={{ height: `100%`, margin: `0 0`, borderRadius: `0.5em` }}
        {...{ ref }}
      />
    </>
  );
}

ZMap.propTypes = {
  clickCallback: PropTypes.func.isRequired,
  mapCenter: PropTypes.object.isRequired,
  existingLocations: PropTypes.array.isRequired,
  currentMarker: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default ZMap;
