import React, { useEffect, useRef, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import AuthContext from '../context/Auth/auth';
import { getGoogleMapsApiKey } from '../config';
import {
  setMapOptions,
  createMarker,
  getNewMarkers,
  createCircle,
  clearLastMarker,
  createExistingZoneMarkers,
  isLocationInExistingZone,
  createParentZoneForScavengerPoints,
  createExistingPointMarkers,
  locationBoundsChecker,
} from '../helpers/MapHelper';
import { isEmpty } from '../helpers/ObjectHelper';

function Map({
  markers,
  className,
  clickCallback,
  radius,
  selectedAccount,
  selectedChild,
  locations,
  currentLocation,
  proximity,
  isScavengerPoint,
  parentZoneForScavengerPoint,
  color,
  mapCenter,
  showClearBtn,
  lat,
  lng,
}) {
  const context = useContext(AuthContext);
  const ref = useRef();
  const [map, setMap] = useState();
  const [markerList, setMarkerList] = useState([]);
  const [scavengerMarker, setScavengerMarker] = useState({});
  const [lastScavengerMarker, setLastScavengerMarker] = useState({});
  const [existingZoneMarkers, setExistingZoneMarkers] = useState([]); // eslint-disable-line no-unused-vars
  const [existingZoneCircles, setExistingZoneCircles] = useState([]);
  const [parentLocationForPoint, setParentLocationForPoint] = useState({}); // eslint-disable-line no-unused-vars
  const maxMarkers = 20;
  const [sessionRadius, setSessionRadius] = useState(radius);
  const [sessionColor, setSessionColor] = useState(color);

  let defaultLat;
  let defaultLng;

  //#region [rgba(0, 205, 30, 0.1)] Map Component Load
  const onLoad = () => {
    if (window.google) {
      // Load map with default lat/lng
      if (!isEmpty(mapCenter)) {
        defaultLat = mapCenter.lat;
        defaultLng = mapCenter.lng;
      } else if (context.tbProps.selectedPark) {
        defaultLat = context.tbProps.selectedPark.Latitude;
        defaultLng = context.tbProps.selectedPark.Longitude;
      } else {
        defaultLat = 35.7766542;
        defaultLng = -78.6452486;
      }

      let options = setMapOptions(currentLocation, defaultLat, defaultLng);

      if (isScavengerPoint) {
        options = setMapOptions(
          parentZoneForScavengerPoint,
          context.tbProps.selectedPark.Latitude,
          context.tbProps.selectedPark.Longitude
        );
      }

      let newMap = new window.google.maps.Map(ref.current, options);

      if (isScavengerPoint) {
        // Event listener added to zone circle object instead of map.
      } else {
        newMap.addListener('click', mapsMouseEvent => {
          handleClickEventForZone(mapsMouseEvent);
        });
      }

      setMap(newMap);

      // All locations get rendered on map.
      if (locations) {
        if (isScavengerPoint) {
          const [markers, circles] = createExistingPointMarkers(
            newMap,
            locations,
            currentLocation.Id
          );

          setExistingZoneMarkers(markers);
          setExistingZoneCircles(circles);
        } else {
          const [markers, circles] = createExistingZoneMarkers(
            newMap,
            locations,
            currentLocation.ZoneId
          );

          setExistingZoneMarkers(markers);
          setExistingZoneCircles(circles);
        }
      }

      // Parent zone is container for points.
      if (parentZoneForScavengerPoint) {
        newMap.setZoom(20);
        const parentZone = createParentZoneForScavengerPoints(
          newMap,
          parentZoneForScavengerPoint
        );

        parentZone.addListener('click', mapsMouseEvent => {
          handleClickEventForPoint(mapsMouseEvent);
        });

        setParentLocationForPoint(parentZone);
      }

      // Editing a location should see previous location.
      if (currentLocation && currentLocation.Latitude !== 0) {
        const lat = currentLocation.Latitude;
        const lng = currentLocation.Longitude;

        setScavengerMarker({ lat, lng });
      }
    }
  };
  //#endregion

  //#region [rgba(231,76,60,0.1)] Click Events
  const handleClickEventForZone = mapsMouseEvent => {
    if (clickCallback) {
      const result = {
        lat: parseFloat(mapsMouseEvent.latLng.lat().toFixed(7)),
        lng: parseFloat(mapsMouseEvent.latLng.lng().toFixed(7)),
      };

      const inExistingLocation = isLocationInExistingZone(
        result.lat,
        result.lng,
        existingZoneCircles
      );
      console.log(sessionRadius);
      locationBoundsChecker(result, sessionRadius, locations);

      if (!inExistingLocation) {
        clickCallback(result);
        setScavengerMarker(result);
      }
      // TODO:: Display message if click was in existing location.
    }
  };

  const handleClickEventForPoint = mapsMouseEvent => {
    if (clickCallback) {
      const result = {
        lat: parseFloat(mapsMouseEvent.latLng.lat().toFixed(7)),
        lng: parseFloat(mapsMouseEvent.latLng.lng().toFixed(7)),
      };

      clickCallback(result);
      setScavengerMarker(result);
    }
  };

  const handleClearMarkerClicked = event => {
    event.preventDefault();

    clearLastMarker(lastScavengerMarker);
    setScavengerMarker({});
  };
  //#endregion

  //#region [rgba(52,152,219,0.1)] useEffects
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement(`script`);

      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` + getGoogleMapsApiKey();
      document.head.append(script);
      script.addEventListener(`load`, onLoad);

      return () => script.removeEventListener(`load`, onLoad);
    } else {
      onLoad();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    markerList.forEach(marker => {
      marker.m.setMap(null);
      marker.m = null;
    });

    setMarkerList([]);
  }, [selectedAccount, selectedChild]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (markers) {
      let newMarkers = getNewMarkers(markers, markerList);

      if (newMarkers?.length > 0) {
        let updatedMarkersList = [...markerList];
        updatedMarkersList.sort((a, b) => a['i'] - b['i']);
        newMarkers
          .sort((a, b) => a['Id'] - b['Id'])
          .forEach(marker => {
            const latitude = parseFloat(marker.Latitude);
            const longitude = parseFloat(marker.Longitude);
            const m = new window.google.maps.Marker({
              map,
              position: { lat: latitude, lng: longitude },
              title: `${marker.Id}`,
            });
            updatedMarkersList.push({ i: marker.Id, m: m });
            if (updatedMarkersList.length > maxMarkers) {
              let rem = updatedMarkersList.shift();
              if (rem) {
                rem.m.setMap(null);
                rem.m = null;
              }
            }
          });
        for (let i = updatedMarkersList.length - 1; i > -1; i--) {
          if (i === updatedMarkersList.length - 1) {
            updatedMarkersList[i].m.setOpacity(1);
            updatedMarkersList[i].m.setIcon(null);
          } else {
            let op = (i / maxMarkers) * 0.5 + 0.2;
            updatedMarkersList[i].m.setOpacity(op);
            updatedMarkersList[i].m.setIcon({
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 5,
              strokeWeight: 3,
              strokeColor: 'blue',
              fillColor: 'blue',
              fillOpacity: op,
            });
          }
        }

        setMarkerList(updatedMarkersList);
      }
    }
  }, [markers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scavengerMarker && window.google) {
      clearLastMarker(lastScavengerMarker);
      const m = createMarker(map, scavengerMarker.lat, scavengerMarker.lng);
      m.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeWeight: 3,
        strokeColor: 'black',
        fillColor: 'black',
      });

      // Radius bug
      let r = radius;
      if (!(r > 0)) {
        r = 20;
      }

      const circle = createCircle(map, r, sessionColor);
      circle.bindTo('center', m, 'position');
      setLastScavengerMarker({ marker: m, circle: circle });

      if (isScavengerPoint) {
        const prox = createCircle(map, r + 10, 'black');
        prox.bindTo('center', m, 'position');
        setLastScavengerMarker({ marker: m, circle: circle, prox: prox });
      }
    }
  }, [scavengerMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (radius && lastScavengerMarker.circle) {
      lastScavengerMarker.circle.setRadius(radius);
      setSessionRadius(radius);
    }
  }, [radius]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (proximity && lastScavengerMarker.circle) {
      lastScavengerMarker.prox.setRadius(proximity);
    }
  }, [proximity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (color && lastScavengerMarker.circle) {
      lastScavengerMarker.circle.setOptions({ fillColor: color });
      setSessionColor(color);
    }
  }, [color]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(mapCenter)) {
      const options = setMapOptions(null, mapCenter.lat, mapCenter.lng);

      let newMap = new window.google.maps.Map(ref.current, options);

      setMap(newMap);
    }
  }, [mapCenter]);

  useEffect(() => {
    if (lat !== undefined && lng !== undefined) {
      const result = {
        lat: parseFloat(lat.toFixed(7)),
        lng: parseFloat(lng.toFixed(7)),
      };

      const inExistingLocation = isLocationInExistingZone(
        result.lat,
        result.lng,
        existingZoneCircles
      );

      locationBoundsChecker(result, sessionRadius, locations);

      if (!inExistingLocation) {
        clickCallback(result);
        setScavengerMarker(result);
      }
    }
  }, [lat, lng]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion

  return (
    <>
      <div
        style={{ height: `100%`, margin: `0 0`, borderRadius: `0.5em` }}
        {...{ ref, className }}
      />
      {showClearBtn ? (
        <Button
          onClick={e => handleClearMarkerClicked(e)}
          variant='danger'
          style={{ marginTop: '10px' }}
          disabled={Object.entries(scavengerMarker).length === 0}
        >
          Clear Marker
        </Button>
      ) : null}
    </>
  );
}

export default Map;
