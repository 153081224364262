export const URLS = {
  OVERVIEW: '/overview',
  DISPATCH: '/dispatch',
  EXPLORER: '/explorer-quest',
  DEVICES: '/device-management',
  USERS: '/user-management',
  TRACKER: '/tracker',
  TOOLS: '/trakid-tools',
  MANUFACTURING: '/manufacturing',
};
