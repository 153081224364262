import React, { useState, useEffect } from 'react';
import { addAccountEntry } from 'services/AccountsService';

import { Button } from 'react-bootstrap';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function AccountSpoofer({
  listKey,
  spoofer,
  deleteCallback,
  context,
}) {
  const [track, setTrack] = useState([]);
  const [id, setId] = useState();

  useEffect(() => {
    let timer = null;
    if (id && track.length > 0) {
      timer = setInterval(() => {
        let point = track.shift();
        track.push(point);
        addAccountEntry(
          spoofer.AccountId,
          point.Latitude,
          point.Longitude,
          context
        );
      }, spoofer.Interval * 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTrack([...spoofer.Track.Track]);
    setId(spoofer.AccountId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function deleteSpoofer() {
    setId(null);
    deleteCallback(listKey);
  }

  return (
    <TableRow key={spoofer.AccountId}>
      <TableCell component='th' scope='row'>
        {spoofer.AccountId}
      </TableCell>
      <TableCell>{spoofer.Interval}</TableCell>
      <TableCell>{spoofer.Track.Name}</TableCell>
      <TableCell>
        <Button variant='danger' onClick={deleteSpoofer}>
          <DeleteForeverIcon />
        </Button>
      </TableCell>
    </TableRow>
  );
}
