import { Grid, Typography } from '@mui/material';

interface IProps {
  activityType: number;
}
const ActivityDescriptions = (props: IProps) => {
  return (
    <>
      {props.activityType === 1 ? (
        <Grid item xs={12}>
          <Typography>
            Prompt users to tap a button after the directed action has been
            performed. Useful for progressing the game after asking players to
            do something the technology can't verify.
          </Typography>
        </Grid>
      ) : null}
      {props.activityType === 2 ? (
        <Grid item xs={12}>
          <Typography>
            Offer 2-4 options and matching responses. Optionally, mark an option
            as the correct one. Useful for knowledge checks, dialogue
            simulation, or expression.
          </Typography>
        </Grid>
      ) : null}
      {props.activityType === 3 ? (
        <Grid item xs={12}>
          <Typography>
            Prompt users to free write text. Optionally, mark the input as
            correct only after validation. Useful for knowledge checks, dialogue
            simulation, or expression.
          </Typography>
        </Grid>
      ) : null}
    </>
  );
};

export default ActivityDescriptions;
