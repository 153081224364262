import { useState, useContext, useEffect } from 'react';
//import { AgGridColumn, AgGridReact } from 'ag-grid-react';
// Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-grid.css';
// Optional Theme applied to the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css';
// React Data Grid Component
import { AgGridReact } from 'ag-grid-react';

import AuthContext from 'context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';
import {
  getBatches,
  getBatch,
  updateTrackerMaster,
} from 'services/TrackerMastersService';

import { makeStyles } from '@mui/styles'
import {
  InputLabel,
  FormControl,
  FormHelperText,
  NativeSelect,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: '25px',
    width: '200px',
  },
}));

function BatchTable() {
  const context = useContext(AuthContext);
  const { state, dispatch } = useContext(UserContext);
  const classes = useStyles();

  
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [fetchingBatches, setFetchingBatches] = useState(false);
  const [batches, setBatches] = useState([]);
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const [batch, setBatch] = useState([]);
  const [tracker, setTracker] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  const handleEditStart = params => {
    // console.log('start', params);
    setTracker(params.data);
  };

  const handleEditStop = params => {
    // console.log('stop', params);
    updateTrackerMaster(context, params.data).then(response => {
      console.log(response);
    });
  };

  const setCellClicked = params => {
    // console.log('clicked', params);
  };

  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleBatchChange = event => {
    const { value } = event.target;

    setSelectedBatchId(value);
    getBatch(context, +value).then(response => {
      setBatch(response.data);
    });
  };

  useEffect(() => {
    setFetchingBatches(true);
    setLoading(true);

    const allowedToEdit = hasAuthorization(state.userRoles, [18]);
    setIsEditable(allowedToEdit);

    getBatches(context).then(response => {
      setBatches(response.data);
      setFetchingBatches(false);
    });
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!fetchingBatches && (
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor='batch-native-helper'>Batches</InputLabel>
          <NativeSelect
            value={selectedBatchId}
            onChange={handleBatchChange}
            inputProps={{
              name: 'batch',
              id: 'batch-native-helper',
            }}
          >
            <option aria-label='None' value='' />
            {batches.map(batch => {
              return (
                <option key={batch.BatchId} value={batch.BatchId}>
                  Batch {batch.BatchId}
                </option>
              );
            })}
          </NativeSelect>
          <FormHelperText></FormHelperText>
        </FormControl>
      )}

      <div className='ag-theme-alpine' style={{ height: 600, width: '100%' }}>
        {/*<AgGridReact
          rowData={batch}
          defaultColDef={{
            editable: true,
            resizable: true,
          }}
          onCellEditingStarted={handleEditStart}
          onCellEditingStopped={handleEditStop}
          onCellDoubleClicked={setCellClicked}
          onGridReady={onGridReady}
        >
          <AgGridColumn
            field='UniqueId'
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field='Imei'
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field='IpAddress'
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field='Note'
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field='PhoneNumber'
            sortable={true}
            filter={true}
          ></AgGridColumn>
          <AgGridColumn
            field='SimNumber'
            sortable={true}
            filter={true}
          ></AgGridColumn>
        </AgGridReact>
        */}
      </div>
    </>
  );
}

export default BatchTable;
