import { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import AuthContext from 'context/Auth/auth';
import { getGoogleMapsApiKey } from 'config';
import {
  setMapOptions,
  createMarker,
  createCircle,
  clearLastMarker,
  isLocationInExistingZone,
  createParentZoneForScavengerPoints,
  createExistingPointMarkers,
  locationBoundsChecker,
} from 'helpers/MapHelper';
import { isEmpty } from 'helpers/ObjectHelper';
import { Point } from 'models/Point';
import { Zone } from 'models/Zone';

import { Paper, Grid, CircularProgress } from '@mui/material';

function WaypointMap({
  mapClickCallback,
  currentLocation,
  lat,
  lng,
  radius,
  proximity,
  color,
  allWaypoints,
  belongsToZone,
  mapCenter,
}) {
  const authContext = useContext(AuthContext);
  const ref = useRef();

  const [loading, setLoading] = useState(false);
  const [map, setMap] = useState();
  const [scavengerMarker, setScavengerMarker] = useState({});
  const [lastScavengerMarker, setLastScavengerMarker] = useState({});
  const [existingZoneMarkers, setExistingZoneMarkers] = useState([]); // eslint-disable-line no-unused-vars
  const [existingZoneCircles, setExistingZoneCircles] = useState([]);
  const [parentLocationForPoint, setParentLocationForPoint] = useState({}); // eslint-disable-line no-unused-vars
  const [sessionRadius, setSessionRadius] = useState(radius);
  const [sessionColor, setSessionColor] = useState(color);

  let defaultLat;
  let defaultLng;

  //#region [rgba(0, 205, 30, 0.1)] MAP INITIAL LOAD
  const onLoad = () => {
    setLoading(true);

    if (window.google) {
      if (!isEmpty(mapCenter)) {
        defaultLat = mapCenter.lat;
        defaultLng = mapCenter.lng;
      } else {
        defaultLat = authContext.tbProps.selectedPark.Latitude;
        defaultLng = authContext.tbProps.selectedPark.Longitude;
      }

      const options = setMapOptions(belongsToZone, defaultLat, defaultLng);

      let wayPointCMSMap = new window.google.maps.Map(ref.current, options);
      wayPointCMSMap.setTilt(0);
      setMap(wayPointCMSMap);

      // All allWaypoints get rendered on map.
      if (allWaypoints) {
        const [markers, circles] = createExistingPointMarkers(
          wayPointCMSMap,
          allWaypoints,
          currentLocation.Id
        );

        setExistingZoneMarkers(markers);
        setExistingZoneCircles(circles);
      }

      // Parent zone is container for points.
      if (belongsToZone) {
        wayPointCMSMap.setZoom(20);
        const parentZone = createParentZoneForScavengerPoints(
          wayPointCMSMap,
          belongsToZone
        );

        parentZone.addListener('click', mapsMouseEvent => {
          handleClickEventForPoint(mapsMouseEvent);
        });

        setParentLocationForPoint(parentZone);
      }

      // Editing a location should see previous location.
      if (currentLocation && currentLocation.Latitude !== 0) {
        const lat = currentLocation.Latitude;
        const lng = currentLocation.Longitude;

        setScavengerMarker({ lat, lng });
      }

      setLoading(false);
    }
  };
  //#endregion

  //#region [rgba(231,76,60,0.1)] Click Events
  const handleClickEventForPoint = mapsMouseEvent => {
    if (mapClickCallback) {
      const result = {
        lat: parseFloat(mapsMouseEvent.latLng.lat().toFixed(7)),
        lng: parseFloat(mapsMouseEvent.latLng.lng().toFixed(7)),
      };

      mapClickCallback(result);
      setScavengerMarker(result);
    }
  };
  //#endregion

  //#region [rgba(52,152,219,0.1)] useEffects
  // useEffect(() => {
  //   console.log('currentPoint', currentLocation);
  // }, [currentLocation]);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement(`script`);

      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` + getGoogleMapsApiKey();
      document.head.append(script);
      script.addEventListener(`load`, onLoad);

      return () => script.removeEventListener(`load`, onLoad);
    } else {
      onLoad();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (scavengerMarker && window.google) {
      clearLastMarker(lastScavengerMarker);

      const m = createMarker(map, scavengerMarker.lat, scavengerMarker.lng);
      m.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeWeight: 3,
        strokeColor: 'black',
        fillColor: 'black',
      });

      // Radius bug
      let r = radius;
      if (!(r > 0)) {
        r = 20;
      }

      const circle = createCircle(map, r, sessionColor);
      circle.bindTo('center', m, 'position');
      setLastScavengerMarker({ marker: m, circle: circle });

      const prox = createCircle(map, proximity, 'black');
      prox.bindTo('center', m, 'position');
      setLastScavengerMarker({ marker: m, circle: circle, prox: prox });
    }
  }, [scavengerMarker]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (radius && lastScavengerMarker.circle) {
      lastScavengerMarker.circle.setRadius(radius);
      setSessionRadius(radius);
    }
  }, [radius]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (proximity && lastScavengerMarker.circle) {
      lastScavengerMarker.prox.setRadius(proximity);
    }
  }, [proximity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (color && lastScavengerMarker.circle) {
      lastScavengerMarker.circle.setOptions({ fillColor: color });
      setSessionColor(color);
    }
  }, [color]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isEmpty(mapCenter)) {
      const options = setMapOptions(null, mapCenter.lat, mapCenter.lng);

      let wayPointCMSMap = new window.google.maps.Map(ref.current, options);

      setMap(wayPointCMSMap);
    }
  }, [mapCenter]);

  useEffect(() => {
    const result = {
      lat: parseFloat(lat.toFixed(7)),
      lng: parseFloat(lng.toFixed(7)),
    };

    const inExistingLocation = isLocationInExistingZone(
      result.lat,
      result.lng,
      existingZoneCircles
    );

    locationBoundsChecker(result, sessionRadius, allWaypoints);

    if (!inExistingLocation) {
      mapClickCallback(result);
      setScavengerMarker(result);
    }
  }, [lat, lng]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div
          style={{ height: `100%`, margin: `0 0`, borderRadius: `0.5em` }}
          {...{ ref }}
        />
      )}
    </>
  );
}

WaypointMap.propTypes = {
  mapClickCallback: PropTypes.func.isRequired,
  currentLocation: PropTypes.objectOf(Point),
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
  proximity: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  allWaypoints: PropTypes.arrayOf(Point),
  belongsToZone: PropTypes.objectOf(Zone).isRequired,
};

export default WaypointMap;
