import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';

const InfoButton = withStyles(() => ({
  root: {
    color: '#f8f8f8',
    backgroundColor: '#7ba5fd',
    '&:hover': {
      backgroundColor: '#7ba5fd',
    },
    '&:disabled': {
      backgroundColor: '#bdbdbd',
      color: '#424242',
    },
  },
}))(Button);

export default InfoButton;
