import { IActivity } from 'models/IActivity';

import { makeStyles } from '@mui/styles'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'flex',
    //margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface IProps {
  selectLabel: string;
  selectName: string;
  activity: IActivity;
  handleChange: (e: EventTarget) => void;
  items: { value: number; name: string }[];
}
function FormSelect(props: IProps) {
  const classes = useStyles();

  return (
    <>
      <FormControl variant='outlined' className={classes.formControl}>
        <InputLabel id={props.selectLabel}>{props.selectName}</InputLabel>
        <Select
          labelId={props.selectLabel}
          value={props.activity.ActivityType}
          onChange={e => props.handleChange(e.target)}
          label='Waypoint Activity'
        >
          {props.items !== undefined &&
            props.items.map((item, idx) => {
              return (
                <MenuItem key={idx} value={item.value}>
                  {item.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
}

export default FormSelect;
