import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';

import BatchTable from './DevTools/logs/BatchTable';

function Manufacturing() {
  const { state } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  
  // Check for Authorization
  useEffect(() => {
    setLoading(true);

    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1])) {
        navigate('/not-authorized');
      } else {
        setLoading(false);
      }
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{!loading && <BatchTable />}</>;
}

export default Manufacturing;
