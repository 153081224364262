import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { useRoutes, A, navigate } from 'hookrouter';
import { useCookies } from 'react-cookie';
import WebFontConfig from 'webfontloader';

//import AppContainer from './components/AppContainer';

import { getBaseUrl } from './config';
import { useLocalStorage } from './custom-hooks';

// Context
import AuthContextProvider from './context/Auth';
import { UserContext, UserContextProvider } from 'context/UserContext';

import PrimaryAppBar from './components/common/PrimaryAppBar';
import SideNavList from './components/common/SideNavList';
// import Notification from './helpers/Notification';
//import Theme from './styles/theme';

// Pages
import LoginPage from './components/pages/Login';
import NoAccess from 'components/pages/NoAccess';
import SignIn from './components/pages/Login/SignIn';
import AccountAdmin from './components/pages/AccountAdmin';
import DevTools from './components/pages/DevTools';
import Manufacturing from 'components/pages/Manufacturing';
import Dispatch from './components/pages/Dispatch';
import LostChild from './components/pages/LostChild';
import Overview from './components/pages/Overview';
import Devices from './components/pages/Devices';
import UserManagement from './components/pages/UserManagement';
import ExplorerQuest from './components/pages/ExplorerQuest';
import TrackerView from './components/pages/TrackerView';
import ZoneCMS from './components/pages/ExplorerQuest/cms/pages/ZoneCMS';
import WaypointCMS from './components/pages/ExplorerQuest/cms/pages/WayPointCMS';
import Profile from './components/pages/Profile';

import clsx from 'clsx';
//import { makeStyles } from '@mui/styles';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { makeStyles, useTheme } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import 'styles/App.css';

const url = getBaseUrl();

const Logout = () => {
  const [, , removeCookie] = useCookies(['token']);
  useEffect(() => {
    removeCookie('token');
    navigate('/', true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <p>Closing session</p>;
};

WebFontConfig.load({
  google: {
    families: ['Source Sans Pro', 'Work Sans'],
  },
});

const routes = {
  '/': () => <SignIn />,
  '/not-authorized': () => <NoAccess />,
  '/signin': () => <SignIn />,
  '/login': () => <LoginPage />,
  '/Login': () => <LoginPage />,
  '/logout': () => <Logout />,
  '/accounts': () => <AccountAdmin />,
  '/trakid-tools': () => <DevTools />,
  '/manufacturing': () => <Manufacturing />,
  '/dispatch': () => <Dispatch />,
  '/LostChild': () => <LostChild baseUrl={url} />,
  '/overview': () => <Overview />,
  '/explorer-quest': () => <ExplorerQuest />,
  '/explorer-quest/zone/new': () => <ZoneCMS />,
  '/explorer-quest/zone/:id': ({ id }) => <ZoneCMS zoneId={id} />,
  '/explorer-quest/zone/:zoneId/waypoint/new': ({ zoneId }) => (
    <WaypointCMS zoneId={zoneId} />
  ),
  '/explorer-quest/zone/:zoneId/waypoint/:wpId/edit': ({ zoneId, wpId }) => (
    <WaypointCMS zoneId={zoneId} wpId={wpId} />
  ),
  '/tracker': () => <TrackerView baseUrl={url} />,
  '/TrackerView/Lost': () => <TrackerView baseUrl={url} />,
  '/device-management': () => <Devices />,
  '/user-management': () => <UserManagement />,
  '/profile': () => <Profile />,
};

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const theme = createTheme();
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
}));

function Component({ children }) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

function MainComponent() {
  const classes = useStyles();
  const Router = useRoutes(routes);
  return <main className={classes.content}>
    <div className={classes.toolbar} />
    {Router}
  </main>;
}

function DrawerComponent({ children, cookies, open }) {
  const classes = useStyles();
  return (
    <Drawer
      variant='permanent'
      hidden={!cookies.token}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      {children}
    </Drawer>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);



function RightsComponent() {
  const classes = useStyles();
  return <div className={classes.toolbar}>
    <Typography variant='subtitle2'>
      TRAKID &copy; 2021
      <br />
      <em>v0.3.0</em>
    </Typography>
  </div>;
}

function App(props) {
  const Router = useRoutes(routes);
  const { state, dispatch } = useContext(UserContext);
  const [cookies] = useCookies(['token']); // eslint-disable-line no-unused-vars

  const [selectedOrg, setSelectedOrg] = useState(null);
  const [orgListOpen, setOrgListOpen] = useState(false);
  const [selectedPark, setSelectedPark] = useState(null);
  const [parkListOpen, setParkListOpen] = useState(false);
  const [parkBoundary, setParkBoundary] = useState([]);
  const [parkId, setParkId] = useLocalStorage('parkId', null);
  const [parkLat, setParkLat] = useLocalStorage('parkLat', null);
  const [parkLng, setParkLng] = useLocalStorage('parkLng', null);
  const [organizations, setOrganizations] = useState([]);
  const [parks, setParks] = useState([]);
  const [, setDisplayNotification] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);


  //const classes = useStyles();
  //const theme = useTheme();

  const adminProps = {
    selectedOrg,
    setSelectedOrg,
    orgListOpen,
    setOrgListOpen,
    selectedPark,
    setSelectedPark,
    parkListOpen,
    setParkListOpen,
    setDisplayNotification,
    parkBoundary,
    setParkBoundary,
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedPark) {
      setParkId(selectedPark.ParkId);
    }
  }, [selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  // TODO Update nav UI
  // return Router ? (
  //   <ThemeProvider theme={Theme}>
  //     <AppContainer>
  //       <AuthContextProvider adminProps={adminProps}>
  //         {Router}
  //       </AuthContextProvider>
  //     </AppContainer>
  //   </ThemeProvider>
  // ) : (
  //   <h1>
  //     Error 404: <A href='/'>Go back</A>
  //   </h1>
  // );

  return Router ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthContextProvider adminProps={adminProps}>
        <Component >
            <CssBaseline />
            <AppBar position="fixed" open={open}>              
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={[
                    {
                      marginRight: 5,
                    },
                    open && { display: 'none' },
                  ]}
                >
                  <MenuIcon />
                </IconButton>
                <PrimaryAppBar
                hidden={!cookies.token}
                adminProps={adminProps}
                shift={open}
                />
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </DrawerHeader>
              <SideNavList
                handleOpen={handleDrawerOpen}
                handleClose={handleDrawerClose}
                isOpen={open}
              />
            </Drawer>
              <MainComponent {...props} />
        </Component>
      </AuthContextProvider>
    </ThemeProvider>
  ) : (
    <h1>
      Error 404: <A href='/'>Go back</A>
    </h1>
  );
}


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <UserContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </UserContextProvider>
);
