import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';

//#region Styles
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
//#endregion

function ConfirmationDialog({ open, close, confirm, warning }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
      confirm();
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={close}
      aria-labelledby='confirm-deletion'
      aria-describedby='confirm-deletion-warning'
    >
      <DialogTitle id='confirm-deletion'>
        {'Continue with Deletion?'}
      </DialogTitle>
      <DialogContent>
        {warning ? (
          <DialogContentText id='confirm-deletion-warning'>
            <em>
              Warning: Deleting a zone will also delete all scavenger hunt
              points for that zone.
            </em>
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color='primary' disabled={loading}>
          Close
        </Button>
        <Button
          variant='contained'
          color='secondary'
          disabled={loading}
          onClick={handleButtonClick}
        >
          Continue
        </Button>
        {loading && (
          <CircularProgress size={34} className={classes.buttonProgress} />
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  warning: PropTypes.bool,
};

export default ConfirmationDialog;
