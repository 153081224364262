import { Tutorial } from 'models/Tutorial';

import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid';

import gameStatesBg from '../../../../../images/cms/blank-screens/how-to-play-4.png';
import defaultImg from '../../../../../images/cms/welcome/park-logo-placeholder.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    justifyItems: 'center',
    background: `url(${gameStatesBg}) no-repeat center`,
    backgroundSize: 'contain',
    // height: '700px',
    height: '654px',

    '& > *': {
      boxSizing: 'content-box',
      // border: '1px solid red',
      // width: '320px',
    },
  },
  headerText: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '30px',
    marginBottom: 0,

    '& > h3': {
      fontSize: '1.4rem',
    },
  },
  description: {
    color: '#000000',
    textAlign: 'left',
    fontSize: '1.3rem',
    maxWidth: '300px',
    marginBottom: '10px',
    marginTop: '5px',
    width: '300px',

    '& > h3': {
      fontSize: '1.35rem',
      fontWeight: 'bolder',
    },

    '& > p': {
      fontSize: '16px',
    },
  },
  compassImage: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    maxWidth: '320px',
    width: '302px',
    height: '350px',
    marginTop: '80px',
    overflow: 'hidden',
  },
  image: {
    position: 'relative',
    left: '50px',
    height: '150px',
    width: '150px',
    borderRadius: '100%',
  },
}));

interface Props {
  tutorial: Tutorial;
  compassImage: string | null;
}
function EngagePreview({ tutorial, compassImage }: Props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item className={classes.headerText}>
          {tutorial.StateText3 !== '' ? (
            <h3>{tutorial.StateText3}</h3>
          ) : (
            <h3>Help</h3>
          )}
        </Grid>

        <Grid item className={classes.description}>
          {tutorial.Title3 !== '' ? (
            <h3>{tutorial.Title3}</h3>
          ) : (
            <h3>
              <em>Title Placeholder</em>
            </h3>
          )}

          {tutorial.Description3 !== '' ? (
            <p>{tutorial.Description3}</p>
          ) : (
            <p>
              <em>Description Placeholder</em>
            </p>
          )}
        </Grid>

        <Grid item className={classes.compassImage}>
          {compassImage !== null ? (
            <img
              src={compassImage}
              alt='Compass Imagery'
              className={classes.image}
            />
          ) : (
            <img
              src={defaultImg}
              alt='Default Imagery'
              className={classes.image}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default EngagePreview;
