import QuestionOption from './QuestionOption';

export default class Question {
  Id: number | null;
  AnswerPosition: number | null;
  Options: QuestionOption[];

  constructor(
    id: number | null,
    answerPosition: number | null,
    options: QuestionOption[]
  ) {
    this.Id = id;
    this.AnswerPosition = answerPosition;
    this.Options = options;
  }
}
