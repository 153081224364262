import React, { useState, useEffect, useContext, useRef } from 'react';
import { navigate } from 'hookrouter';
import { Row, Col, Container, Button, Image } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';

import DispatchMap from '../../DispatchMap';
import AuthContext from '../../../context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { hasAuthorization } from 'helpers/HasAuthorization';
import { getTrackerEntries } from '../../../services/TrackersService';
import { getAccountEntries } from '../../../services/AccountsService';
import { getLostChildren } from '../../../services/ChildrenService';
import { convertGender, convertStatus } from '../../../helpers/EnumHelper';
import { getDuration } from '../../../helpers/TimeHelper';

import './Dispatch.css';

export default function Dispatch() {
  const coordinateCount = 20;
  const [lostChildren, setLostChildren] = useState([]);
  const [selectedChild, setSelectedChild] = useState(null);
  const [childMarkers, setChildMarkers] = useState([]);
  const [parentMarkers, setParentMarkers] = useState([]);
  const [staffMarkers] = useState([]);
  const [mapCenter, setMapCenter] = useState({});
  const [zoom, setZoom] = useState(null);
  const [sortName, setSortName] = useState(false);
  const [sortAge, setSortAge] = useState(false);
  const [sortStatus, setSortStatus] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const selectedChildRef = useRef();
  const sortNameRef = useRef();
  const sortAgeRef = useRef();
  const sortStatusRef = useRef();

  const context = useContext(AuthContext);
  const { state, dispatch } = useContext(UserContext);

  selectedChildRef.current = selectedChild;
  sortNameRef.current = sortName;
  sortAgeRef.current = sortAge;
  sortStatusRef.current = sortStatus;

  // Check for Authorization
  useEffect(() => {
    if (state.orgId !== null) {
      if (!hasAuthorization(state.userRoles, [1, 2, 3, 4, 5, 7])) {
        navigate('/not-authorized');
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sortName) {
      setSortAge(false);
      setSortStatus(false);
    }
  }, [sortName]);

  useEffect(() => {
    if (sortAge) {
      setSortName(false);
      setSortStatus(false);
    }
  }, [sortAge]);

  useEffect(() => {
    if (sortStatus) {
      setSortName(false);
      setSortAge(false);
    }
  }, [sortStatus]);

  useEffect(() => {
    if (!context.token) {
      navigate('/logout', true);
    }
  }, [context.token]);

  useEffect(() => {
    let timer = null;
    if (context.tbProps.selectedPark) {
      setMapCenter({
        lat: context.tbProps.selectedPark.Latitude,
        lng: context.tbProps.selectedPark.Longitude,
      });
      setZoom(context.tbProps.selectedPark.Zoom);
      timer = setInterval(() => {
        getLostChildren(
          context.tbProps.selectedPark.ParkId,
          context,
          lostChildrenCallback
        );
      }, 1000);
    } else {
      clearInterval(timer);
      setLostChildren([]);
    }
    return () => clearInterval(timer);
  }, [context.tbProps.selectedPark]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer = null;
    if (selectedChild && selectedChild.TrackerId) {
      timer = setInterval(() => {
        getTrackerEntries(
          selectedChild.TrackerId,
          selectedChild.AccountId,
          coordinateCount,
          context,
          setChildMarkers
        );
        getAccountEntries(
          selectedChild.AccountId,
          coordinateCount,
          context,
          setParentMarkers
        );
      }, 1000);
    } else {
      clearInterval(timer);
      setChildMarkers([]);
      setParentMarkers([]);
    }
    return () => clearInterval(timer);
  }, [selectedChild]); // eslint-disable-line react-hooks/exhaustive-deps

  const reportedLostTime = time => {
    let minutes = getDuration(time);

    if (minutes === 0) return <span>&lt;1 min</span>;
    if (minutes < 10)
      return (
        <span className='green-minute-text'>{minutes.toString()} min</span>
      );
    if (minutes < 20 && minutes >= 10)
      return (
        <span className='yellow-minute-text'>{minutes.toString()} min</span>
      );

    if (minutes > 59) {
      let hours = Math.floor(minutes / 60);
      let mins = minutes % 60;
      return (
        <span className='red-minute-text'>
          {hours.toString()} hrs {mins.toString()} min
        </span>
      );
    }

    return <span className='red-minute-text'>{minutes.toString()} min</span>;
  };

  const lostChildrenCallback = data => {
    if (sortNameRef.current) {
      data.sort((a, b) => b.Nickname - a.Nickname);
    } else if (sortAgeRef.current) {
      data.sort((a, b) => a.Age - b.Age);
    } else if (sortStatusRef.current) {
      data.sort((a, b) => b.LostStatus - a.LostStatus);
    } else {
      data.sort(
        (a, b) => Date.parse(a.ReportedLost) - Date.parse(b.ReportedLost)
      );
    }

    setLostChildren(data);

    let found = data.find(c => c.ChildId === selectedChildRef.current.ChildId);
    if (found) {
      let copy = [...found];
      setSelectedChild(copy);
    } else {
      setSelectedChild(null);
    }
  };

  function viewLostChild(data) {
    setSelectedChild(data);
  }

  return (
    <>
      <div className='dispatch-flex-grow-column dispatch-s-container dispatch-container'>
        <div className='dispatch-flex-row'>
          <div className='dispatch-header-title'>Separated Children</div>
          <div className='statistics-toggle hide-on-mobile'>
            {showStats ? (
              <button
                className='alc-sort-toggle-on'
                onClick={() => setShowStats(false)}
              >
                STATISTICS
              </button>
            ) : (
              <button
                className='alc-sort-toggle-off'
                onClick={() => setShowStats(true)}
              >
                STATISTICS
              </button>
            )}
          </div>
        </div>

        <div
          className='dispatch-flex-grow-row dispatch-flex-s-column'
          style={{ height: '85vh' }}
        >
          <div className='dispatch-flex-column dispatch-flex-s-row d-col-s-12 d-col-8 d-row-s-12'>
            {showStats ? (
              <div className='dispatch-map d-row-s-12 d-col-s-12'>
                <DispatchMap
                  childMarkers={childMarkers}
                  parentMarkers={parentMarkers}
                  staffMarkers={staffMarkers}
                  selectedChild={selectedChild}
                  mapCenter={mapCenter}
                  zoom={zoom}
                />
              </div>
            ) : (
              <div className='dispatch-map-full-size d-row-s-12 d-col-s-12'>
                <DispatchMap
                  childMarkers={childMarkers}
                  parentMarkers={parentMarkers}
                  staffMarkers={staffMarkers}
                  selectedChild={selectedChild}
                  mapCenter={mapCenter}
                  zoom={zoom}
                />
              </div>
            )}
            {showStats ? (
              <Container fluid className='lost-child-stats'>
                <Row>
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    className='lost-child-stats-box'
                  >
                    <Row>
                      <Col className='lost-child-stats-box-title'>
                        Average Reunite Time <strong>Today</strong>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    className='lost-child-stats-box'
                  >
                    <Row>
                      <Col className='lost-child-stats-box-title'>
                        Average Reunite Time <strong>7 Days</strong>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    className='lost-child-stats-box flex-fill'
                  >
                    <Row>
                      <Col className='lost-child-stats-box-title'>
                        Average Reunite Time <strong>30 Days</strong>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    className='lost-child-stats-box flex-fill'
                  >
                    <Row>
                      <Col className='lost-child-stats-box-title'>
                        Total Children Found <strong>30 Days</strong>
                      </Col>
                      <Col></Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            ) : (
              <div></div>
            )}
          </div>
          <div className='dispatch-flex-grow-column'>
            <div className='alc-view dispatch-flex-column'>
              <div>
                <div className='alc-sort-title'>Sort by</div>
              </div>
              <div className='alc-sort-options'>
                {sortName ? (
                  <button
                    className='alc-sort-toggle-on'
                    onClick={() => setSortName(false)}
                  >
                    NAME
                  </button>
                ) : (
                  <button
                    className='alc-sort-toggle-off'
                    onClick={() => setSortName(true)}
                  >
                    NAME
                  </button>
                )}
                {sortAge ? (
                  <button
                    className='alc-sort-toggle-on'
                    onClick={() => setSortAge(false)}
                  >
                    AGE
                  </button>
                ) : (
                  <button
                    className='alc-sort-toggle-off'
                    onClick={() => setSortAge(true)}
                  >
                    AGE
                  </button>
                )}
                {sortStatus ? (
                  <button
                    className='alc-sort-toggle-on'
                    onClick={() => setSortStatus(false)}
                  >
                    STATUS
                  </button>
                ) : (
                  <button
                    className='alc-sort-toggle-off'
                    onClick={() => setSortStatus(true)}
                  >
                    STATUS
                  </button>
                )}
              </div>

              {lostChildren.length > 3 ? (
                <div className='dispatch-flex-column alc-list'>
                  {lostChildren.map((item, index) => (
                    <div className='dispatch-flex-column'>
                      <div key={index} className='dispatch-flex-grow-row'>
                        <div className='alc-view-button'>
                          {!selectedChild ||
                          selectedChild.ChildId !== item.ChildId ? (
                            <Button
                              variant='success'
                              className='p-2'
                              onClick={() => viewLostChild(item)}
                            >
                              View
                            </Button>
                          ) : (
                            <Button
                              variant='danger'
                              className='p-2'
                              onClick={() => setSelectedChild(null)}
                            >
                              Stop
                            </Button>
                          )}
                        </div>
                        <div className='dispatch-flex-column alc-child-details'>
                          <FontAwesome
                            name='lc-text'
                            className='alc-child-name'
                          >
                            {item.Nickname}
                          </FontAwesome>
                          <FontAwesome name='lc-text' className='alc-child-age'>
                            Age: {item.Age}
                          </FontAwesome>
                        </div>
                        <FontAwesome
                          name='lc-text'
                          className='alc-child-status'
                        >
                          Reported: {reportedLostTime(item.ReportedLost)}
                        </FontAwesome>
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
              ) : lostChildren ? (
                lostChildren.map((item, index) => (
                  <div className='dispatch-flex-column'>
                    <div key={index} className='dispatch-flex-grow-row'>
                      <div className='alc-view-button'>
                        {!selectedChild ||
                        selectedChild.ChildId !== item.ChildId ? (
                          <Button
                            variant='success'
                            className='p-2'
                            onClick={() => viewLostChild(item)}
                          >
                            View
                          </Button>
                        ) : (
                          <Button
                            variant='danger'
                            className='p-2'
                            onClick={() => setSelectedChild(null)}
                          >
                            Stop
                          </Button>
                        )}
                      </div>
                      <div className='dispatch-flex-column alc-child-details'>
                        <FontAwesome name='lc-text' className='alc-child-name'>
                          {item.Nickname}
                        </FontAwesome>
                        <FontAwesome name='lc-text' className='alc-child-age'>
                          Age: {item.Age}
                        </FontAwesome>
                      </div>
                      <FontAwesome name='lc-text' className='alc-child-status'>
                        Reported: {reportedLostTime(item.ReportedLost)}
                      </FontAwesome>
                    </div>
                    <hr />
                  </div>
                ))
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id='legend' hidden={!selectedChild}>
        <Container fluid className='legend-container'>
          <Row className='legend-name'>
            <Col xs={3}>
              <Image src='https://maps.google.com/mapfiles/ms/icons/red-dot.png' />
            </Col>
            <Col xs={9}>{selectedChild?.Nickname}</Col>
          </Row>
          <Row className='legend-text'>
            <Col>
              <strong>Age:</strong> {selectedChild?.Age}
            </Col>
            <Col>
              <strong>Gender:</strong> {convertGender(selectedChild?.Gender)}
            </Col>
          </Row>
          <Row className='legend-text'>
            <Col>
              <strong>Special Needs:</strong> {selectedChild?.SpecialNeeds}
            </Col>
          </Row>
          <Row className='legend-text'>
            <Col>
              <strong>Reported Missing:</strong>{' '}
              {reportedLostTime(selectedChild?.ReportedLost)} ago
            </Col>
          </Row>
          <Row className='legend-text'>
            <Col>
              <strong>Status:</strong>{' '}
              {convertStatus(selectedChild?.LostStatus)}
            </Col>
            <Col>
              <strong>Updated:</strong>{' '}
              {reportedLostTime(selectedChild?.LostStatusUpdatedAt)} ago
            </Col>
          </Row>
        </Container>
        <Container fluid className='legend-container'>
          <Row className='legend-name'>
            <Col xs={3}>
              <Image src='https://maps.google.com/mapfiles/ms/icons/green-dot.png' />
            </Col>
            <Col xs={9}>
              {selectedChild?.AccountFirstName} {selectedChild?.AccountLastName}
            </Col>
          </Row>
          <Row className='legend-account-type'>
            <Col>Primary Account Holder</Col>
          </Row>
          <Row className='legend-name'>
            <Col>{selectedChild?.AccountPhoneNumber}</Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
