import { useEffect, useState } from 'react';

import { Tutorial } from 'models/Tutorial';

import { makeStyles } from '@mui/styles'
import { Grid, CircularProgress } from '@mui/material';

import defaultImg from '../../../../../images/cms/welcome/park-logo-placeholder.png';
import parkSplash from '../../../../../images/cms/welcome/Park-splash-screen.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    background: `url(${parkSplash}) no-repeat center`,
    backgroundSize: 'contain',
    height: '700px',

    '& > *': {
      boxSizing: 'content-box',
      // border: '1px solid red',
      width: '320px',
    },
  },
  loadingImage: {
    display: 'grid',
    maxWidth: '320px',
    height: '150px',
    marginTop: '80px',
    justifyItems: 'center',
    alignItems: 'center',
  },
  parkImage: {
    display: 'grid',
    maxWidth: '320px',
    marginTop: '80px',
  },
  image: {
    justifySelf: 'center',
    height: '210px',
    width: '210px',
    borderRadius: '10px',
  },
  welcomeText: {
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '15px',
    marginBottom: 0,

    '& > h3': {
      fontSize: '1.4rem',
    },
  },
  questName: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '1.3rem',
    maxWidth: '170px',
  },
}));

interface Props {
  tutorial: Tutorial;
  parkImage: string | null;
  loading: boolean;
}
function WelcomeScreenOnePreview({ tutorial, parkImage, loading }: Props) {
  const classes = useStyles();

  const [image, setImage] = useState(defaultImg);

  useEffect(() => {
    if (parkImage !== null) {
      setImage(parkImage);
    }
  }, [parkImage]);

  return (
    <>
      <Grid container className={classes.root}>
        {loading ? (
          <Grid item className={classes.loadingImage}>
            <CircularProgress color='secondary' />
          </Grid>
        ) : (
          <Grid item className={classes.parkImage}>
            <img src={image} alt='Point Imagery' className={classes.image} />
          </Grid>
        )}

        <Grid item className={classes.welcomeText}>
          <h3>Welcome to the</h3>
        </Grid>

        <Grid item className={classes.questName}>
          {tutorial.ScavengerHuntName !== '' ? (
            <p>{tutorial.ScavengerHuntName}</p>
          ) : (
            <p>
              <em>Quest Name Placeholder</em>
            </p>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default WelcomeScreenOnePreview;
