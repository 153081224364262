import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';


function Elastic() {
  return (
    <>
      <Grid container spacing={2} justifyContent='center' alignItems='center'>
        <Grid size={12} /*xs={12}*/>
          <Typography variant='body1'>
            Elastic Username: <code>dashboard_user</code>
          </Typography>
          <Typography variant='body1'>
            Elastic PW: <code>hAz4Hiaeh6Wf</code>
          </Typography>
        </Grid>
        <Grid size={12} /*xs={12}*/>
          <iframe
            src='https://81c8802498e84abe9aa75dd45999802f.eastus2.azure.elastic-cloud.com:9243/app/dashboards#/view/21ebb5f0-d42b-11eb-aba4-45e61cb35d62?embed=true&_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A0)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))&show-top-menu=true&show-query-input=true&show-time-filter=true'
            height='600'
            width='99.5%'
            title='elastic'
          ></iframe>
        </Grid>
      </Grid>
    </>
  );
}

export default Elastic;
