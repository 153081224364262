import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import { usePath, navigate } from 'hookrouter';
import { HexColorPicker } from 'react-colorful';

import AuthContext from '../../../../../context/Auth/auth';
import ZMap from '../maps/ZMap';
import ConfirmationDialog from '../../../../../helpers/ConfirmationDialog';
import { URLS } from 'urls';
import MapPanel from 'components/common/MapPanel';
import ZoneMap from '../maps/ZoneMap';

import { Zone } from '../../../../../models/Zone';
import {
  fetchNewZone,
  getZone,
  getZones,
  addZone,
  updateZone,
  updateZoneStatus,
  deleteZone,
} from '../../../../../services/ZonesService';

import { makeStyles } from '@mui/styles'
import {
  Paper,
  Tabs,
  Tab,
  Box,
  TextField,
  Avatar,
  Button,
  CircularProgress,
  Popover,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material';;

//#region Setup and Styles
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#FFF',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      textAlign: 'left',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
//#endregion

function ZoneCMS(props) {
  const authContext = useContext(AuthContext);

  const path = usePath();
  const classes = useStyles();

  const [mapCords, setMapCords] = useState({});
  const [currentZone, setCurrentZone] = useState({});
  const [currentZonePosition, setCurrentZonePosition] = useState({});
  const [emptyLatitudeFix, setEmptyLatitudeFix] = useState({
    show: false,
    value: '',
  });
  const [emptyLongitudeFix, setEmptyLongitudeFix] = useState({
    show: false,
    value: '',
  });
  const [currentRadius, setCurrentRadius] = useState(20);
  const [allZones, setAllZones] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isNewZone, setIsNewZone] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [colorPickerEl, setColorPickerEl] = useState(null);

  const [shortNameError, setShortNameError] = useState(false);
  const [radiusError, setRadiusError] = useState({ show: false, msg: '' });
  const [latitudeError, setLatitudeError] = useState({ show: false, msg: '' });
  const [longitudeError, setLongitudeError] = useState({
    show: false,
    msg: '',
  });

  const clearAllErrors = () => {
    setShortNameError(false);
    setRadiusError({ show: false, msg: '' });
    setLatitudeError({ show: false, msg: '' });
    setLongitudeError({ show: false, msg: '' });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFormUpdate = ({ name, value }) => {
    if (name === 'Radius') {
      const radius = parseInt(value);
      if (radius < 1) {
        setRadiusError({ show: true, msg: 'Radius cannot be less than 1' });
      } else if (isNaN(radius)) {
        setRadiusError({ show: true, msg: 'Radius cannot be blank' });
      } else {
        setRadiusError({ show: false, msg: '' });
        setCurrentRadius(radius);
        setCurrentZone({ ...currentZone, [name]: value });
      }
    } else if (name === 'Latitude') {
      if (value === '' || value === '-') {
        setEmptyLatitudeFix({ show: true, value: value });
      } else if (value >= -90 && value <= 90) {
        setEmptyLatitudeFix({ show: false, value: '' });
        setLatitudeError({ show: false, msg: '' });
        setCurrentZonePosition({ ...currentZonePosition, [name]: value });
        setCurrentZone({ ...currentZone, [name]: value });
      } else {
        setEmptyLatitudeFix({ show: false, value: '' });
        setLatitudeError({
          show: true,
          msg: 'A valid latitude is between -90 and 90',
        });
      }
    } else if (name === 'Longitude') {
      if (value === '' || value === '-') {
        setEmptyLongitudeFix({ show: true, value: value });
      } else if (value >= -180 && value <= 180) {
        setEmptyLongitudeFix({ show: false, value: '' });
        setLongitudeError({ show: false, msg: '' });
        setCurrentZonePosition({ ...currentZonePosition, [name]: value });
        setCurrentZone({ ...currentZone, [name]: value });
      } else {
        setEmptyLongitudeFix({ show: false, value: '' });
        setLongitudeError({
          show: true,
          msg: 'A valid longitude is between -180 and 180',
        });
      }
    } else {
      setCurrentZone({ ...currentZone, [name]: value });
    }
  };

  const handleColorUpdate = value => {
    setCurrentZone({ ...currentZone, Color: value });
  };

  const handleColorPickerClick = event => {
    setColorPickerEl(event.currentTarget);
  };

  const handleColorPickerClose = () => {
    setColorPickerEl(null);
  };

  const openColorPicker = Boolean(colorPickerEl);

  const handleMapClick = data => {
    if (data) {
      setCurrentZonePosition({
        Latitude: data.lat,
        Longitude: data.lng,
      });
    }
  };

  const handleValidation = zone => {
    let formHasError = false;

    for (let key in zone) {
      switch (key) {
        case 'ShortName':
          if (zone['ShortName'] === '') {
            formHasError = true;
            setShortNameError(true);
          } else {
            setShortNameError(false);
          }
          break;
        case 'Radius':
          if (zone['Radius'] === '' || Number.isNaN(zone['Radius'])) {
            formHasError = true;
            setRadiusError({ show: true, msg: 'Value cannot be blank' });
          } else {
            setRadiusError({ show: false, msg: '' });
          }
          break;
        case 'Latitude':
          if (zone['Latitude'] === '' || zone['Latitude'] === 0) {
            formHasError = true;
            setLatitudeError({ show: true, msg: 'Value cannot be blank' });
          } else {
            setLatitudeError({ show: false, msg: '' });
          }
          break;
        case 'Longitude':
          if (zone['Longitude'] === '' || zone['Longitude'] === 0) {
            formHasError = true;
            setLongitudeError({ show: true, msg: 'Value cannot be blank' });
          } else {
            setLongitudeError({ show: false, msg: '' });
          }
          break;
        default:
          break;
      }
    }

    return formHasError;
  };

  const handleSave = () => {
    setSaving(true);

    const zone = { ...currentZone };
    zone.Latitude = currentZonePosition.Latitude;
    zone.Longitude = currentZonePosition.Longitude;
    zone.Radius = currentRadius;

    const errors = handleValidation(zone);

    if (!errors) {
      if (isNewZone) {
        addZone(
          authContext,
          zone,
          authContext.tbProps.selectedPark.ParkId
        ).then(_data => {
          setSaving(false);
          navigate(URLS.EXPLORER);
        });
      } else {
        updateZone(
          authContext,
          zone,
          authContext.tbProps.selectedPark.ParkId,
          currentZone.ZoneId
        ).then(_data => {
          updateZoneStatus(
            authContext,
            zone.ParkId,
            zone.ZoneId,
            zone.IsActive
          ).then(_msg => {
            setSaving(false);
            navigate(URLS.EXPLORER);
          });
        });
      }
    } else {
      setSaving(false);
    }
  };

  const handleConfirmDialogOpen = () => {
    setShowConfirmation(true);
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmation(false);
  };

  const handleDelete = () => {
    deleteZone(authContext, currentZone.ParkId, currentZone.ZoneId).then(
      _data => {
        setShowConfirmation(false);
        navigate('/explorer-quest');
      }
    );
  };

  useEffect(() => {
    if (authContext.tbProps.selectedPark) {
      setLoading(true);

      const parkLat = authContext.tbProps.selectedPark.Latitude;
      const parkLng = authContext.tbProps.selectedPark.Longitude;
      const parkId = authContext.tbProps.selectedPark.ParkId;
      const zoneId = parseInt(props.zoneId);
      const isNewZone = path.includes('zone/new'); // move check to props

      //TODO
      // setCookie('park', authContext.tbProps.selectedPark.ParkId);
      // localStorage.setItem('park', authContext.tbProps.selectedPark.ParkId);

      setMapCords({
        lat: parkLat,
        lng: parkLng,
      });

      clearAllErrors();

      getZones(authContext, parkId).then(data => {
        const zones = data.map(z => {
          return new Zone(
            z.ZoneId,
            z.ShortName,
            z.LongName,
            z.Description,
            z.Latitude,
            z.Longitude,
            z.Radius,
            z.Color,
            z.ParkId,
            z.IsActive
          );
        });

        setAllZones(zones);

        if (isNewZone) {
          const zone = fetchNewZone();
          setCurrentZone({ ...zone, Latitude: parkLat, Longitude: parkLng });
          setCurrentZonePosition({
            Latitude: parkLat,
            Longitude: parkLng,
          });
          setCurrentRadius(zone.Radius);
          setIsNewZone(true);

          setLoading(false);
        } else {
          getZone(authContext, parkId, zoneId).then(data => {
            setCurrentZone(data);
            setCurrentZonePosition({
              Latitude: data.Latitude,
              Longitude: data.Longitude,
            });
            setCurrentRadius(data.Radius);
            setIsNewZone(false);

            setLoading(false);
          });
        }
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {/* Tab Header */}
        <Grid item size={12}>
          <Paper className={classes.paper}>
            <Tabs
              value={tabValue}
              indicatorColor='primary'
              textColor='primary'
              onChange={handleTabChange}
              variant='fullWidth'
              aria-label='zone tab options'
            >
              <Tab label='Location Information' {...a11yProps(0)} />
            </Tabs>
          </Paper>
        </Grid>

        {/* Tab Panels */}
        <Grid
          item
          xs={12}
          md={6}
          direction='column'
          justify='center'
          alignItems='stretch'
        >
          <Paper className={classes.paper}>
            <TabPanel value={tabValue} index={0}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Grid
                  container
                  spacing={2}
                  direction='column'
                  justify='flex-start'
                  alignItems='stretch'
                >
                  {/* <form className={classes.form} noValidate autoComplete='off'> */}
                  <Grid item>
                    <TextField
                      id='zone-shortname'
                      label='Short Name'
                      name='ShortName'
                      helperText={shortNameError ? 'Cannot be blank' : ''}
                      variant='outlined'
                      value={currentZone.ShortName}
                      onChange={e => handleFormUpdate(e.target)}
                      error={shortNameError}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id='zone-longname'
                      label='Long Name'
                      name='LongName'
                      helperText=''
                      variant='outlined'
                      value={currentZone.LongName}
                      onChange={e => handleFormUpdate(e.target)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id='zone-description'
                      label='Description'
                      name='Description'
                      helperText=''
                      variant='outlined'
                      value={currentZone.Description}
                      onChange={e => handleFormUpdate(e.target)}
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      id='zone-latitude'
                      label='Latitude'
                      name='Latitude'
                      helperText={latitudeError.show ? latitudeError.msg : ''}
                      variant='outlined'
                      value={
                        emptyLatitudeFix.show
                          ? emptyLatitudeFix.value
                          : currentZonePosition.Latitude
                      }
                      onChange={e => handleFormUpdate(e.target)}
                      error={latitudeError.show}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id='zone-longitude'
                      label='Longitude'
                      name='Longitude'
                      helperText={longitudeError.show ? longitudeError.msg : ''}
                      variant='outlined'
                      value={
                        emptyLongitudeFix.show
                          ? emptyLongitudeFix.value
                          : currentZonePosition.Longitude
                      }
                      onChange={e => handleFormUpdate(e.target)}
                      error={longitudeError.show}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id='zone-radius'
                      label='Radius'
                      name='Radius'
                      helperText={radiusError.show ? radiusError.msg : ''}
                      variant='outlined'
                      value={currentZone.Radius}
                      onChange={e => handleFormUpdate(e.target)}
                      error={radiusError.show}
                      type='number'
                      fullWidth
                    />
                  </Grid>
                  <Grid item style={{ alignSelf: 'baseline' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={7}>
                        <TextField
                          id='waypoint-color'
                          label='Color'
                          name='Color'
                          helperText=''
                          variant='outlined'
                          value={currentZone.Color}
                          onChange={e => handleFormUpdate(e.target)}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Avatar
                          style={{
                            backgroundColor: currentZone.Color,
                            marginTop: '7px',
                            cursor: 'pointer',
                          }}
                          onClick={handleColorPickerClick}
                        >
                          &nbsp;
                        </Avatar>
                      </Grid>
                    </Grid>
                    <Popover
                      open={openColorPicker}
                      anchorEl={colorPickerEl}
                      onClose={handleColorPickerClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className={classes.colorPicker}>
                        <HexColorPicker
                          color={currentZone.Color}
                          onChange={handleColorUpdate}
                        />
                      </div>
                    </Popover>
                  </Grid>
                  {/* </form> */}
                </Grid>
              )}
            </TabPanel>

            {/* Actions */}
            <Paper
              className={classes.paper}
              style={{ textAlign: 'right', boxShadow: 'none' }}
            >
              {isNewZone ? null : (
                <Button
                  size='small'
                  disabled={loading || saving}
                  color='secondary'
                  startIcon={<DeleteIcon />}
                  onClick={handleConfirmDialogOpen}
                >
                  Delete Zone
                </Button>
              )}
              <Button
                size='small'
                disabled={loading || saving}
                color='primary'
                startIcon={<SaveIcon />}
                onClick={handleSave}
              >
                Save Zone
              </Button>
            </Paper>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper
            className={classes.paper}
            style={{ padding: '0', height: '100%', borderRadius: '10px' }}
          >
            <MapPanel value={tabValue} index={0} style={{ height: '100%' }}>
              {loading ? (
                <CircularProgress />
              ) : (
                <div
                  className='modal-map-row'
                  style={{ height: '100%', padding: 0 }}
                >
                  {/*<ZoneMap
                    clickCallback={handleMapClick}
                    mapCenter={{ lat: mapCords.lat, lng: mapCords.lng }}
                    locations={allZones}
                    radius={parseInt(currentRadius)}
                    lat={parseFloat(currentZonePosition.Latitude)}
                    lng={parseFloat(currentZonePosition.Longitude)}
                    currentLocation={currentZone}
                  />*/}
                  <ZMap
                    clickCallback={handleMapClick}
                    mapCenter={{ lat: mapCords.lat, lng: mapCords.lng }}
                    existingLocations={allZones}
                    currentMarker={currentZone}
                    lat={parseFloat(currentZonePosition.Latitude)}
                    lng={parseFloat(currentZonePosition.Longitude)}
                    radius={parseInt(currentRadius)}
                    color={currentZone.Color}
                  />
                </div>
              )}
            </MapPanel>
          </Paper>
        </Grid>
      </Grid>

      <ConfirmationDialog
        open={showConfirmation}
        close={handleConfirmDialogClose}
        confirm={handleDelete}
      />
    </div>
  );
}

ZoneCMS.propTypes = {
  zoneId: PropTypes.string,
};

export default ZoneCMS;
