export const spooferTracks = [
  {
    Id: 1,
    Name: 'Downtown - HQ Raleigh',
    Track: [
      { Latitude: 35.77706, Longitude: -78.645278 },
      { Latitude: 35.776887, Longitude: -78.645255 },
      { Latitude: 35.776741, Longitude: -78.645266 },
      { Latitude: 35.776469, Longitude: -78.645282 },
      { Latitude: 35.776262, Longitude: -78.645295 },
      { Latitude: 35.776038, Longitude: -78.645298 },
      { Latitude: 35.775857, Longitude: -78.645306 },
      { Latitude: 35.775857, Longitude: -78.645046 },
      { Latitude: 35.775844, Longitude: -78.644856 },
      { Latitude: 35.77584, Longitude: -78.644612 },
      { Latitude: 35.775903, Longitude: -78.644559 },
      { Latitude: 35.776068, Longitude: -78.644553 },
      { Latitude: 35.776316, Longitude: -78.64455 },
      { Latitude: 35.776438, Longitude: -78.644552 },
      { Latitude: 35.776586, Longitude: -78.644545 },
      { Latitude: 35.77681, Longitude: -78.644529 },
      { Latitude: 35.776963, Longitude: -78.644519 },
      { Latitude: 35.777049, Longitude: -78.644526 },
      { Latitude: 35.777058, Longitude: -78.644698 },
      { Latitude: 35.77706, Longitude: -78.644937 },
      { Latitude: 35.777058, Longitude: -78.645133 },
    ],
  },
  {
    Id: 2,
    Name: 'Gateway - HQ Raleigh',
    Track: [
      { Latitude: 35.805604, Longitude: -78.61308 },
      { Latitude: 35.805526, Longitude: -78.613134 },
      { Latitude: 35.805411, Longitude: -78.613214 },
      { Latitude: 35.805302, Longitude: -78.613319 },
      { Latitude: 35.805289, Longitude: -78.613517 },
      { Latitude: 35.805343, Longitude: -78.61374 },
      { Latitude: 35.805392, Longitude: -78.613938 },
      { Latitude: 35.805431, Longitude: -78.614131 },
      { Latitude: 35.805379, Longitude: -78.614222 },
      { Latitude: 35.805253, Longitude: -78.614276 },
      { Latitude: 35.805118, Longitude: -78.614322 },
      { Latitude: 35.804992, Longitude: -78.614376 },
      { Latitude: 35.804881, Longitude: -78.614312 },
      { Latitude: 35.804888, Longitude: -78.614135 },
      { Latitude: 35.804864, Longitude: -78.613971 },
      { Latitude: 35.804831, Longitude: -78.61381 },
      { Latitude: 35.804796, Longitude: -78.613665 },
      { Latitude: 35.804763, Longitude: -78.61352 },
      { Latitude: 35.804733, Longitude: -78.613367 },
      { Latitude: 35.804705, Longitude: -78.613222 },
      { Latitude: 35.804696, Longitude: -78.613045 },
      { Latitude: 35.804811, Longitude: -78.612935 },
      { Latitude: 35.804955, Longitude: -78.612828 },
      { Latitude: 35.805086, Longitude: -78.612734 },
      { Latitude: 35.805247, Longitude: -78.612667 },
      { Latitude: 35.805395, Longitude: -78.612629 },
      { Latitude: 35.805449, Longitude: -78.612803 },
      { Latitude: 35.805519, Longitude: -78.612926 },
      { Latitude: 35.805586, Longitude: -78.613015 },
    ],
  },
  {
    Id: 3,
    Name: 'Kings Dominion',
    Track: [
      { Latitude: 37.839709, Longitude: -77.445687 },
      { Latitude: 37.839565, Longitude: -77.446087 },
      { Latitude: 37.839379, Longitude: -77.446581 },
      { Latitude: 37.838989, Longitude: -77.446753 },
      { Latitude: 37.838599, Longitude: -77.446624 },
      { Latitude: 37.838167, Longitude: -77.446367 },
      { Latitude: 37.837811, Longitude: -77.446013 },
      { Latitude: 37.837574, Longitude: -77.445509 },
      { Latitude: 37.837557, Longitude: -77.445058 },
      { Latitude: 37.837896, Longitude: -77.444661 },
      { Latitude: 37.838252, Longitude: -77.444243 },
      { Latitude: 37.838565, Longitude: -77.4439 },
      { Latitude: 37.838853, Longitude: -77.443567 },
      { Latitude: 37.839022, Longitude: -77.443395 },
      { Latitude: 37.839107, Longitude: -77.443695 },
      { Latitude: 37.839404, Longitude: -77.444017 },
      { Latitude: 37.839794, Longitude: -77.44436 },
      { Latitude: 37.839946, Longitude: -77.444811 },
      { Latitude: 37.84031, Longitude: -77.445101 },
      { Latitude: 37.840335, Longitude: -77.445294 },
      { Latitude: 37.840166, Longitude: -77.445294 },
      { Latitude: 37.839971, Longitude: -77.445326 },
      { Latitude: 37.839963, Longitude: -77.445734 },
    ],
  },
  {
    Id: 4,
    Name: 'Hersheypark',
    Track: [
      { Latitude: 40.289736, Longitude: -76.653819 },
      { Latitude: 40.289691, Longitude: -76.654011 },
      { Latitude: 40.289634, Longitude: -76.654215 },
      { Latitude: 40.289792, Longitude: -76.6544 },
      { Latitude: 40.289935, Longitude: -76.654566 },
      { Latitude: 40.290168, Longitude: -76.654893 },
      { Latitude: 40.28998, Longitude: -76.655322 },
      { Latitude: 40.289669, Longitude: -76.655612 },
      { Latitude: 40.289342, Longitude: -76.655821 },
      { Latitude: 40.28908, Longitude: -76.6558 },
      { Latitude: 40.288839, Longitude: -76.655773 },
      { Latitude: 40.288598, Longitude: -76.655644 },
      { Latitude: 40.288303, Longitude: -76.655376 },
      { Latitude: 40.288098, Longitude: -76.655328 },
      { Latitude: 40.287844, Longitude: -76.654818 },
      { Latitude: 40.287963, Longitude: -76.654448 },
      { Latitude: 40.288155, Longitude: -76.654266 },
      { Latitude: 40.288401, Longitude: -76.654014 },
      { Latitude: 40.288683, Longitude: -76.653751 },
      { Latitude: 40.289019, Longitude: -76.653831 },
      { Latitude: 40.289285, Longitude: -76.654035 },
      { Latitude: 40.289547, Longitude: -76.653928 },
    ],
  },
  {
    Id: 5,
    Name: 'Milwaukee County Zoo',
    Track: [
      { Latitude: 43.0318706, Longitude: -88.0414832 },
      { Latitude: 43.0319372, Longitude: -88.041113 },
      { Latitude: 43.031851, Longitude: -88.0405551 },
      { Latitude: 43.0316549, Longitude: -88.040185 },
      { Latitude: 43.0314628, Longitude: -88.0399811 },
      { Latitude: 43.0312, Longitude: -88.0399865 },
      { Latitude: 43.0309216, Longitude: -88.0399436 },
      { Latitude: 43.030604, Longitude: -88.0398041 },
      { Latitude: 43.0303138, Longitude: -88.0396968 },
      { Latitude: 43.0300707, Longitude: -88.0396485 },
      { Latitude: 43.0298354, Longitude: -88.0398095 },
      { Latitude: 43.0296158, Longitude: -88.0400831 },
      { Latitude: 43.0296276, Longitude: -88.04048 },
      { Latitude: 43.029757, Longitude: -88.0411452 },
      { Latitude: 43.0298197, Longitude: -88.0416012 },
      { Latitude: 43.0296785, Longitude: -88.0420679 },
      { Latitude: 43.0296001, Longitude: -88.0422825 },
      { Latitude: 43.0301374, Longitude: -88.0423146 },
      { Latitude: 43.0303648, Longitude: -88.0424488 },
      { Latitude: 43.0306354, Longitude: -88.0427116 },
      { Latitude: 43.0307883, Longitude: -88.0421537 },
      { Latitude: 43.0306746, Longitude: -88.0416548 },
      { Latitude: 43.0309412, Longitude: -88.041864 },
      { Latitude: 43.0312314, Longitude: -88.0419284 },
      { Latitude: 43.0315177, Longitude: -88.0418694 },
      { Latitude: 43.0317294, Longitude: -88.0417031 },
    ],
  },
  {
    Id: 6,
    Name: 'Silverwood Theme Park',
    Track: [
      { Latitude: 47.9102314, Longitude: -116.7037913 },
      { Latitude: 47.910289, Longitude: -116.7035204 },
      { Latitude: 47.9104076, Longitude: -116.7033058 },
      { Latitude: 47.9105676, Longitude: -116.70322 },
      { Latitude: 47.9107295, Longitude: -116.7032415 },
      { Latitude: 47.9109182, Longitude: -116.7030081 },
      { Latitude: 47.9110405, Longitude: -116.702815 },
      { Latitude: 47.9112077, Longitude: -116.7027426 },
      { Latitude: 47.9113982, Longitude: -116.7027774 },
      { Latitude: 47.9115097, Longitude: -116.7027882 },
      { Latitude: 47.9116068, Longitude: -116.7026299 },
      { Latitude: 47.9117488, Longitude: -116.7024904 },
      { Latitude: 47.9118801, Longitude: -116.7023376 },
      { Latitude: 47.9119807, Longitude: -116.7020479 },
      { Latitude: 47.9121533, Longitude: -116.7017341 },
      { Latitude: 47.9122738, Longitude: -116.7014873 },
      { Latitude: 47.9124572, Longitude: -116.7013639 },
      { Latitude: 47.9126531, Longitude: -116.7013344 },
      { Latitude: 47.9128023, Longitude: -116.7014524 },
      { Latitude: 47.9128365, Longitude: -116.7017689 },
      { Latitude: 47.9127862, Longitude: -116.7020962 },
      { Latitude: 47.9125848, Longitude: -116.7019406 },
      { Latitude: 47.9124212, Longitude: -116.7019942 },
      { Latitude: 47.9122684, Longitude: -116.7020077 },
      { Latitude: 47.912103, Longitude: -116.7021579 },
      { Latitude: 47.9120041, Longitude: -116.7024234 },
      { Latitude: 47.9118567, Longitude: -116.7026326 },
      { Latitude: 47.9117147, Longitude: -116.702925 },
      { Latitude: 47.9116104, Longitude: -116.7031583 },
      { Latitude: 47.911472, Longitude: -116.7033032 },
      { Latitude: 47.9113749, Longitude: -116.7037243 },
      { Latitude: 47.9111609, Longitude: -116.7040166 },
      { Latitude: 47.9109955, Longitude: -116.7040595 },
      { Latitude: 47.9108805, Longitude: -116.7038262 },
      { Latitude: 47.9106683, Longitude: -116.7037645 },
      { Latitude: 47.9104742, Longitude: -116.7037913 },
      { Latitude: 47.9103285, Longitude: -116.7038396 },
    ],
  },
  {
    Id: 7,
    Name: 'Regency Park',
    Track: [
      { Latitude: 35.73392, Longitude: -78.794558 },
      { Latitude: 35.733966, Longitude: -78.794617 },
      { Latitude: 35.734009, Longitude: -78.794656 },
      { Latitude: 35.734046, Longitude: -78.794698 },
      { Latitude: 35.734111, Longitude: -78.794788 },
      { Latitude: 35.734122, Longitude: -78.794862 },
      { Latitude: 35.734115, Longitude: -78.79493 },
      { Latitude: 35.734084, Longitude: -78.79499 },
      { Latitude: 35.734039, Longitude: -78.795048 },
      { Latitude: 35.734008, Longitude: -78.79508 },
      { Latitude: 35.733963, Longitude: -78.795108 },
      { Latitude: 35.733924, Longitude: -78.795111 },
      { Latitude: 35.733881, Longitude: -78.795107 },
      { Latitude: 35.733827, Longitude: -78.795088 },
      { Latitude: 35.733761, Longitude: -78.795048 },
      { Latitude: 35.733728, Longitude: -78.795012 },
      { Latitude: 35.733689, Longitude: -78.794974 },
      { Latitude: 35.733654, Longitude: -78.794937 },
      { Latitude: 35.733584, Longitude: -78.794859 },
      { Latitude: 35.733534, Longitude: -78.794788 },
      { Latitude: 35.733493, Longitude: -78.794738 },
      { Latitude: 35.733465, Longitude: -78.794678 },
      { Latitude: 35.733426, Longitude: -78.794611 },
      { Latitude: 35.733428, Longitude: -78.794504 },
      { Latitude: 35.733466, Longitude: -78.794413 },
      { Latitude: 35.73353, Longitude: -78.794352 },
      { Latitude: 35.733574, Longitude: -78.794297 },
      { Latitude: 35.733623, Longitude: -78.794272 },
      { Latitude: 35.733662, Longitude: -78.794276 },
      { Latitude: 35.733701, Longitude: -78.794292 },
      { Latitude: 35.733732, Longitude: -78.794329 },
      { Latitude: 35.733762, Longitude: -78.794357 },
    ],
  },
  {
    Id: 8,
    Name: 'Bronte Park',
    Track: [
      { Longitude: -79.7189963, Latitude: 43.4070429 },
      { Longitude: -79.7190714, Latitude: 43.4069416 },
      { Longitude: -79.7190821, Latitude: 43.4067311 },
      { Longitude: -79.7188354, Latitude: 43.406544 },
      { Longitude: -79.7186422, Latitude: 43.4064193 },
      { Longitude: -79.7184813, Latitude: 43.4063024 },
      { Longitude: -79.718374, Latitude: 43.4061699 },
      { Longitude: -79.7182131, Latitude: 43.406092 },
      { Longitude: -79.7179985, Latitude: 43.4060296 },
      { Longitude: -79.7178376, Latitude: 43.4058659 },
      { Longitude: -79.7177196, Latitude: 43.40571 },
      { Longitude: -79.7176766, Latitude: 43.4055463 },
      { Longitude: -79.7175694, Latitude: 43.4054606 },
      { Longitude: -79.717387, Latitude: 43.4053437 },
      { Longitude: -79.7173762, Latitude: 43.4052268 },
      { Longitude: -79.7172046, Latitude: 43.4051722 },
      { Longitude: -79.7170329, Latitude: 43.4051332 },
      { Longitude: -79.7170007, Latitude: 43.4050553 },
      { Longitude: -79.7171724, Latitude: 43.4050319 },
      { Longitude: -79.7175157, Latitude: 43.4050007 },
      { Longitude: -79.7179234, Latitude: 43.4049384 },
      { Longitude: -79.718256, Latitude: 43.4048994 },
      { Longitude: -79.7186208, Latitude: 43.4047981 },
      { Longitude: -79.7188997, Latitude: 43.4047435 },
      { Longitude: -79.7191358, Latitude: 43.404572 },
      { Longitude: -79.7191358, Latitude: 43.4044629 },
      { Longitude: -79.7190607, Latitude: 43.4042524 },
      { Longitude: -79.7192538, Latitude: 43.404229 },
      { Longitude: -79.7194898, Latitude: 43.4043538 },
      { Longitude: -79.7196186, Latitude: 43.4045408 },
      { Longitude: -79.7198117, Latitude: 43.4046344 },
      { Longitude: -79.7198653, Latitude: 43.4047669 },
      { Longitude: -79.7199941, Latitude: 43.4049072 },
      { Longitude: -79.7201443, Latitude: 43.4050397 },
      { Longitude: -79.7203267, Latitude: 43.4051176 },
      { Longitude: -79.7204447, Latitude: 43.4049306 },
      { Longitude: -79.720391, Latitude: 43.4047591 },
      { Longitude: -79.720434, Latitude: 43.4046655 },
      { Longitude: -79.7206378, Latitude: 43.404611 },
      { Longitude: -79.7208202, Latitude: 43.4046188 },
      { Longitude: -79.720788, Latitude: 43.4047669 },
      { Longitude: -79.7206807, Latitude: 43.404876 },
      { Longitude: -79.7208631, Latitude: 43.4050943 },
      { Longitude: -79.7210455, Latitude: 43.4051098 },
      { Longitude: -79.7210455, Latitude: 43.405219 },
      { Longitude: -79.7209811, Latitude: 43.4053827 },
      { Longitude: -79.7210562, Latitude: 43.4054762 },
      { Longitude: -79.7212172, Latitude: 43.405523 },
      { Longitude: -79.7212708, Latitude: 43.4054138 },
      { Longitude: -79.7213781, Latitude: 43.4051878 },
      { Longitude: -79.7214317, Latitude: 43.4050553 },
      { Longitude: -79.7214746, Latitude: 43.4049072 },
      { Longitude: -79.7215819, Latitude: 43.4048682 },
      { Longitude: -79.7217751, Latitude: 43.4048448 },
      { Longitude: -79.7217858, Latitude: 43.4047669 },
      { Longitude: -79.7216463, Latitude: 43.40465 },
      { Longitude: -79.7215712, Latitude: 43.4045174 },
      { Longitude: -79.7214746, Latitude: 43.4044551 },
      { Longitude: -79.7215819, Latitude: 43.4043927 },
      { Longitude: -79.7215712, Latitude: 43.4042758 },
      { Longitude: -79.721539, Latitude: 43.4042057 },
      { Longitude: -79.7214425, Latitude: 43.4041823 },
      { Longitude: -79.7212815, Latitude: 43.4041823 },
      { Longitude: -79.7209918, Latitude: 43.404229 },
      { Longitude: -79.7208202, Latitude: 43.4041745 },
      { Longitude: -79.7205198, Latitude: 43.4042758 },
      { Longitude: -79.7203803, Latitude: 43.4044629 },
      { Longitude: -79.7202194, Latitude: 43.4046266 },
      { Longitude: -79.7201443, Latitude: 43.4047825 },
    ],
  },
  {
    Id: 9,
    Name: 'Anderson Estates',
    Track: [
      { Latitude: 35.6875714, Longitude: -78.6489758 },
      { Latitude: 35.6875681, Longitude: -78.6489758 },
      { Latitude: 35.6875485, Longitude: -78.6489503 },
      { Latitude: 35.687542, Longitude: -78.6489155 },
      { Latitude: 35.687542, Longitude: -78.6489155 },
      { Latitude: 35.6875714, Longitude: -78.6487961 },
      { Latitude: 35.6875965, Longitude: -78.648776 },
      { Latitude: 35.6876248, Longitude: -78.6487545 },
      { Latitude: 35.6876716, Longitude: -78.6487277 },
      { Latitude: 35.687811, Longitude: -78.6486619 },
      { Latitude: 35.6877283, Longitude: -78.6488216 },
      { Latitude: 35.687737, Longitude: -78.6488793 },
      { Latitude: 35.6877065, Longitude: -78.6489141 },
      { Latitude: 35.6876574, Longitude: -78.6489316 },
      { Latitude: 35.687627, Longitude: -78.648953 },
      { Latitude: 35.6875986, Longitude: -78.6489638 },
      { Latitude: 35.6875714, Longitude: -78.6489758 },
    ],
  },
  {
    Id: 10,
    Name: 'Cincinnati Zoo',
    Track: [
      { Latitude: 39.143665, Longitude: -84.508934 },
      { Latitude: 39.1438306, Longitude: -84.5087403 },
      { Latitude: 39.1439575, Longitude: -84.5086598 },
      { Latitude: 39.144049, Longitude: -84.5084372 },
      { Latitude: 39.1442134, Longitude: -84.5082307 },
      { Latitude: 39.1443132, Longitude: -84.5081529 },
      { Latitude: 39.1443881, Longitude: -84.5080161 },
      { Latitude: 39.1445295, Longitude: -84.5079598 },
      { Latitude: 39.1446003, Longitude: -84.5080376 },
      { Latitude: 39.1447875, Longitude: -84.5080644 },
      { Latitude: 39.1448832, Longitude: -84.5080751 },
      { Latitude: 39.1450246, Longitude: -84.5082039 },
      { Latitude: 39.1449477, Longitude: -84.5083138 },
      { Latitude: 39.1448603, Longitude: -84.5083112 },
      { Latitude: 39.144723, Longitude: -84.5084104 },
      { Latitude: 39.144723, Longitude: -84.5084962 },
      { Latitude: 39.1447812, Longitude: -84.5086867 },
      { Latitude: 39.1447875, Longitude: -84.5088342 },
      { Latitude: 39.1446564, Longitude: -84.5089066 },
      { Latitude: 39.14449, Longitude: -84.5089173 },
      { Latitude: 39.144309, Longitude: -84.5089683 },
      { Latitude: 39.1441052, Longitude: -84.508971 },
      { Latitude: 39.143997, Longitude: -84.5088798 },
      { Latitude: 39.1438348, Longitude: -84.5087725 },
      { Latitude: 39.1436579, Longitude: -84.5089361 },
      { Latitude: 39.143665, Longitude: -84.508934 },
    ],
  },
  {
    Id: 14,
    Name: 'Permisonia',
    Track: [
      { Latitude: 36.070479, Longitude: -79.51898 },
      { Latitude: 36.0705825, Longitude: -79.5186621 },
      { Latitude: 36.0705348, Longitude: -79.5184314 },
      { Latitude: 36.0704221, Longitude: -79.5185548 },
      { Latitude: 36.0703744, Longitude: -79.5186943 },
      { Latitude: 36.0702356, Longitude: -79.5186782 },
      { Latitude: 36.0700145, Longitude: -79.5186406 },
      { Latitude: 36.0699885, Longitude: -79.5185333 },
      { Latitude: 36.0700102, Longitude: -79.518308 },
      { Latitude: 36.0700449, Longitude: -79.5180345 },
      { Latitude: 36.070266, Longitude: -79.5180291 },
      { Latitude: 36.0705131, Longitude: -79.5180452 },
      { Latitude: 36.0708383, Longitude: -79.5180452 },
      { Latitude: 36.0711375, Longitude: -79.5180506 },
      { Latitude: 36.0713977, Longitude: -79.5180935 },
      { Latitude: 36.0714324, Longitude: -79.5183456 },
      { Latitude: 36.0720481, Longitude: -79.5183402 },
      { Latitude: 36.0720004, Longitude: -79.5184851 },
      { Latitude: 36.0714714, Longitude: -79.5184422 },
      { Latitude: 36.0713977, Longitude: -79.5186621 },
      { Latitude: 36.070479, Longitude: -79.51898 },
    ],
  },
  {
    Id: 15,
    Name: 'Mila’s House',
    Track: [
      { Latitude: 35.8801505, Longitude: -78.7437573 },
      { Latitude: 35.88011, Longitude: -78.743611 },
      { Latitude: 35.8802535, Longitude: -78.7435305 },
      { Latitude: 35.8803839, Longitude: -78.7434661 },
      { Latitude: 35.8805142, Longitude: -78.7434071 },
      { Latitude: 35.8806358, Longitude: -78.7433481 },
      { Latitude: 35.8807879, Longitude: -78.7432945 },
      { Latitude: 35.8809182, Longitude: -78.7432247 },
      { Latitude: 35.8810398, Longitude: -78.7431818 },
      { Latitude: 35.8811745, Longitude: -78.7431175 },
      { Latitude: 35.881231, Longitude: -78.7433052 },
      { Latitude: 35.8812701, Longitude: -78.7434715 },
      { Latitude: 35.8812918, Longitude: -78.7436861 },
      { Latitude: 35.8813091, Longitude: -78.7438738 },
      { Latitude: 35.8813091, Longitude: -78.7440187 },
      { Latitude: 35.8813178, Longitude: -78.7441313 },
      { Latitude: 35.8812049, Longitude: -78.7441581 },
      { Latitude: 35.8810919, Longitude: -78.7441635 },
      { Latitude: 35.8810007, Longitude: -78.7441635 },
      { Latitude: 35.8808965, Longitude: -78.7441581 },
      { Latitude: 35.8807966, Longitude: -78.7441313 },
      { Latitude: 35.8806836, Longitude: -78.7441152 },
      { Latitude: 35.8805793, Longitude: -78.7441367 },
      { Latitude: 35.8804099, Longitude: -78.7441903 },
      { Latitude: 35.8802796, Longitude: -78.7442225 },
      { Latitude: 35.8801667, Longitude: -78.744303 },
      { Latitude: 35.8800754, Longitude: -78.7442815 },
      { Latitude: 35.8800407, Longitude: -78.7442011 },
      { Latitude: 35.8800146, Longitude: -78.7440079 },
      { Latitude: 35.880019, Longitude: -78.7438416 },
      { Latitude: 35.8800363, Longitude: -78.743729 },
      { Latitude: 35.880045, Longitude: -78.7436539 },
      { Latitude: 35.880045, Longitude: -78.7436083 },
      { Latitude: 35.8801505, Longitude: -78.7437573 },
    ],
  },
];
