import Location from './Location';
import { Point } from './Point';

export class Zone extends Location {
  ParkId: number | null;
  WayPoints: Point[] | null;

  constructor(
    zoneId: number | null,
    shortName: string,
    longName: string,
    description: string,
    latitude: number,
    longitude: number,
    radius: number,
    color: string,
    parkId: number | null,
    isActive: boolean,
    wayPoints: Point[] | null
  ) {
    super(
      zoneId,
      shortName,
      longName,
      description,
      latitude,
      longitude,
      radius,
      color,
      isActive
    );
    this.ParkId = parkId;
    this.WayPoints = wayPoints;
  }
}

export interface IZone {
  ZoneId: number | null;
  ShortName: string;
  LongName: string;
  Description: string;
  Latitude: number;
  Longitude: number;
  Radius: number;
  Color: string;
  ParkId: number | null;
  IsActive: boolean;
}
