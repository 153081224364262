import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { navigate } from 'hookrouter';

import Dropdown from 'components/Dropdown';
import AuthContext from 'context/Auth/auth';
import InfoButton from 'components/common/buttons/InfoButton';
import TrackerSpoofer from './TrackerSpoofer';
import AccountSpoofer from './AccountSpoofer';

import { spooferTracks } from './spooferTrackData';

import { makeStyles } from '@mui/styles'
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  Input,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  toolCard: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    padding: '10px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'left',
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    width: '100%',
  },
}));

function SpoofingTools({ triggerSnack, setMessage, setSeverity }) {
  const [spoofers, setSpoofers] = useState([]);
  const [accountSpoofers, setAccountSpoofers] = useState([]);

  const [trackerIdInput, setTrackerIdInput] = useState('');

  const [accountIdInput, setAccountIdInput] = useState('');
  const [spooferIntervalInput, setSpooferIntervalInput] = useState('');
  const [accountSpooferIntervalInput, setAccountSpooferIntervalInput] =
    useState('');
  const [selectedSpooferTrack, setSelectedSpooferTrack] = useState(null);
  const [selectedAccountSpooferTrack, setSelectedAccountSpooferTrack] =
    useState(null);
  const [addSpooferButtonDisabled, setAddSpooferButtonDisabled] =
    useState(true);
  const [addAccountSpooferButtonDisabled, setAddAccountSpooferButtonDisabled] =
    useState(true);
  const [idInUseDisabled, setIdInUseDisabled] = useState(true);
  const [accountIdInUseDisabled, setAccountIdInUseDisabled] = useState(true);

  const context = useContext(AuthContext);
  const classes = useStyles();

  //#region useEffects
  useEffect(() => {
    if (!context.token) {
      navigate('/logout', true);
    }
  }, [context.token]);

  useEffect(() => {
    if (trackerIdInput !== '') {
      let found = spoofers.find(s => s.TrackerId === trackerIdInput);
      if (found) {
        setAddSpooferButtonDisabled(true);
        setIdInUseDisabled(false);
      } else {
        setIdInUseDisabled(true);
      }
    }
    if (
      trackerIdInput !== '' &&
      spooferIntervalInput !== '' &&
      selectedSpooferTrack
    ) {
      setAddSpooferButtonDisabled(false);
    } else {
      setAddSpooferButtonDisabled(true);
    }
  }, [trackerIdInput, spooferIntervalInput, selectedSpooferTrack]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accountIdInput !== '') {
      let found = accountSpoofers.find(s => s.AccountId === accountIdInput);
      if (found) {
        setAddAccountSpooferButtonDisabled(true);
        setAccountIdInUseDisabled(false);
      } else {
        setAccountIdInUseDisabled(true);
      }
    }
    if (
      accountIdInput !== '' &&
      accountSpooferIntervalInput !== '' &&
      selectedAccountSpooferTrack
    ) {
      setAddAccountSpooferButtonDisabled(false);
    } else {
      setAddAccountSpooferButtonDisabled(true);
    }
  }, [
    accountIdInput,
    accountSpooferIntervalInput,
    selectedAccountSpooferTrack,
  ]); // eslint-disable-line react-hooks/exhaustive-deps
  //#endregion

  //#region Tool Functions
  function addSpoofer() {
    setSpoofers([
      ...spoofers,
      {
        SpooferId: spoofers.length,
        TrackerId: trackerIdInput,
        Interval: parseInt(spooferIntervalInput),
        Track: selectedSpooferTrack,
      },
    ]);

    setTrackerIdInput('');
    setSpooferIntervalInput('');
  }

  function addAccountSpoofer() {
    setAccountSpoofers([
      ...accountSpoofers,
      {
        SpooferId: spoofers.length,
        AccountId: accountIdInput,
        Interval: parseInt(accountSpooferIntervalInput),
        Track: selectedAccountSpooferTrack,
      },
    ]);

    setAccountIdInput('');
  }

  function removeSpoofer(index) {
    let copy = [...spoofers];
    copy.splice(index, 1);
    setSpoofers(copy);
  }

  function removeAccountSpoofer(index) {
    let copy = [...accountSpoofers];
    copy.splice(index, 1);
    setAccountSpoofers(copy);
  }
  //#endregion

  const trackTitle = track => (
    <Row className='stretch-row'>
      <Col>{track.Name}</Col>
    </Row>
  );

  return (
    <>
      <Grid container spacing={2}>
        {/* SPOOF TRACKER */}
        <Grid item xs={12} lg={6}>
          <Card className={classes.toolCard}>
            <CardContent>
              <Typography
                className={classes.title}
                color='textSecondary'
                gutterBottom
              >
                Create Tracker Spoofer
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={4}>
                  <form style={{ display: 'flex' }}>
                    <FormControl>
                      <InputLabel htmlFor='trackerSpooferIdInput'>
                        Tracker ID
                      </InputLabel>
                      <Input
                        id='trackerSpooferIdInput'
                        value={trackerIdInput}
                        onChange={e => setTrackerIdInput(e.target.value)}
                      />
                    </FormControl>
                  </form>
                </Grid>

                <Grid item xs={6} md={4} lg={4}>
                  <form style={{ display: 'flex' }}>
                    <FormControl>
                      <InputLabel htmlFor='trackerIntervalInput'>
                        Spoofer Interval
                      </InputLabel>
                      <Input
                        id='trackerIntervalInput'
                        value={spooferIntervalInput}
                        onChange={e => setSpooferIntervalInput(e.target.value)}
                      />
                    </FormControl>
                  </form>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <div className='track-dropdown'>
                    <Dropdown
                      items={spooferTracks}
                      toggleItem={setSelectedSpooferTrack}
                      titleCreator={trackTitle}
                      initialTitle={'Select Track'}
                    />
                  </div>

                  {/* <FormControl className={classes.formControl}>
                    <InputLabel id='tracker-spoofer'>Select Track</InputLabel>
                    <Select
                      labelId='tracker-spoofer'
                      id='tracker-spoofer'
                      value={selectedSpooferTrack}
                      onChange={setSelectedSpooferTrack}
                    >
                      {spooferTracks.map(spoofer => {
                        return (
                          <MenuItem key={spoofer.Id} value={spoofer.Name}>
                            {spoofer.Name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                </Grid>
              </Grid>

              <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Tracker ID</TableCell>
                      <TableCell>Interval</TableCell>
                      <TableCell>Track</TableCell>
                      <TableCell>Battery</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {spoofers.map((item, index) => (
                      <TrackerSpoofer
                        key={index}
                        listKey={index}
                        spoofer={item}
                        deleteCallback={removeSpoofer}
                      ></TrackerSpoofer>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions>
              <InfoButton
                onClick={addSpoofer}
                disabled={addSpooferButtonDisabled}
              >
                Add Tracker Spoofer
              </InfoButton>
            </CardActions>
          </Card>
        </Grid>

        {/* SPOOF ACCOUNT */}
        <Grid item xs={12} lg={6}>
          <Card className={classes.toolCard}>
            <CardContent>
              <Typography
                className={classes.title}
                color='textSecondary'
                gutterBottom
              >
                Create Account Spoofer
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6} md={4} lg={4}>
                  <form style={{ display: 'flex' }}>
                    <FormControl>
                      <InputLabel htmlFor='accountIdInput'>
                        Account ID
                      </InputLabel>
                      <Input
                        id='accountIdInput'
                        value={accountIdInput}
                        onChange={e => setAccountIdInput(e.target.value)}
                      />
                    </FormControl>
                  </form>
                </Grid>

                <Grid item xs={6} md={4} lg={4}>
                  <form style={{ display: 'flex' }}>
                    <FormControl>
                      <InputLabel htmlFor='accountIntervalInput'>
                        Spoofer Interval
                      </InputLabel>
                      <Input
                        id='accountIntervalInput'
                        value={accountSpooferIntervalInput}
                        onChange={e =>
                          setAccountSpooferIntervalInput(e.target.value)
                        }
                      />
                    </FormControl>
                  </form>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <div className='track-dropdown'>
                    <Dropdown
                      items={spooferTracks}
                      toggleItem={setSelectedAccountSpooferTrack}
                      titleCreator={trackTitle}
                      initialTitle={'Select Track'}
                    />
                  </div>
                </Grid>
              </Grid>

              <TableContainer component={Paper} style={{ marginTop: '10px' }}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Account ID</TableCell>
                      <TableCell>Interval</TableCell>
                      <TableCell>Track</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {accountSpoofers.map((item, index) => (
                      <AccountSpoofer
                        key={index}
                        listKey={index}
                        spoofer={item}
                        context={context}
                        deleteCallback={removeAccountSpoofer}
                      ></AccountSpoofer>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
            <CardActions>
              <InfoButton
                onClick={addAccountSpoofer}
                disabled={addAccountSpooferButtonDisabled}
              >
                Add Account Spoofer
              </InfoButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

SpoofingTools.propTypes = {
  triggerSnack: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setSeverity: PropTypes.func.isRequired,
};

export default SpoofingTools;
