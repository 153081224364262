import React, { useState, useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import '../styles/Dropdown.css';

export default function Dropdown({
  items,
  toggleItem,
  titleCreator,
  initialTitle,
  listOpenCallback,
  closeDropdown,
  disabled,
}) {
  const [listOpen, setListOpen] = useState(false);
  const [title, setTitle] = useState(initialTitle);

  function toggleSelected(item) {
    toggleItem(item);
    setListOpen(false);
    setTitle(titleCreator(item));
  }

  useEffect(() => {
    if (listOpenCallback) {
      listOpenCallback(listOpen);
    }
  }, [listOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (closeDropdown) {
      setListOpen(false);
    }
  }, [closeDropdown]);

  useEffect(() => {
    setTitle(initialTitle);
  }, [items, disabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='dd-wrapper'>
      {!disabled ? (
        <div>
          <div className='dd-header' onClick={() => setListOpen(!listOpen)}>
            <div className='dd-header-title'>{title}</div>
            {listOpen ? (
              <FontAwesome name='angle-up' className='angle' size='2x' />
            ) : (
              <FontAwesome name='angle-down' className='angle' size='2x' />
            )}
          </div>
          {listOpen && (
            <ul className='dd-list'>
              {items.map((item, index) => (
                <li
                  className='dd-list-item'
                  key={index}
                  onClick={() => toggleSelected(item)}
                >
                  {titleCreator(item)}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <div className='dd-header-disabled'>
          <div className='dd-header-title'>{title}</div>
          {listOpen ? (
            <FontAwesome name='angle-up' className='angle' size='2x' />
          ) : (
            <FontAwesome name='angle-down' className='angle' size='2x' />
          )}
        </div>
      )}
    </div>
  );
}
