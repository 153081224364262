interface Props {
  index: number;
}
function A11yProps(props: Props) {
  return {
    id: `scrollable-auto-tab-${props.index}`,
    'aria-controls': `scrollable-auto-tabpanel-${props.index}`,
  };
}

export default A11yProps;
