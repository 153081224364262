import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const CustomInput = ({ handleChange, label, value, ...props }) => (
  <TextField
    onChange={e => handleChange(e.target)}
    label={label}
    defaultValue={value}
    variant='outlined'
    {...props}
  />
);

CustomInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomInput;
