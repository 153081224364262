import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Marker = styled.div`
  width: ${props => props.radius};
  height: ${props => props.radius};
  background-color: ${props => props.color};
  border-radius: 100%;
  transform: translate(-50%, -50%);
  /* position: relative;
  top: ${props => `-${props.radius / 2}`};
  left: ${props => `-${props.radius / 2}`}; */
  opacity: 0.75;
`;

function ZoneMarker({ name, radius, color }) {
  return (
    <Marker radius={radius} color={color}>
      {name}
    </Marker>
  );
}

ZoneMarker.propTypes = {
  name: PropTypes.string,
  radius: PropTypes.number,
  color: PropTypes.string,
};

export default ZoneMarker;
