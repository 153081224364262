import { memo, useContext, useEffect } from 'react';
import { A } from 'hookrouter';

import AuthContext from 'context/Auth/auth';
import { UserContext } from 'context/UserContext';
import { getUserByToken } from 'services/Users.Service';
import { URLS } from 'urls';
import { IPark } from 'models/IPark';
import { hasAuthorization } from 'helpers/HasAuthorization';

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import {
  Build as BuildIcon,
  Dashboard as DashboardIcon,
  TrackChanges as TrackChangesIcon,
  Explore as ExploreIcon,
  People as PeopleIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Search as SearchIcon,
  Watch as WatchIcon,
  SdCard as SdCardIcon,
} from '@mui/icons-material';;

interface Props {
  handleOpen: () => void;
  handleClose: () => void;
  isOpen: boolean;
}
const SideNavList = (props: Props) => {
  const context = useContext(AuthContext);
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    if (context.token) {
      getUserByToken(context.token).then(user => {
        const { UserRoles, EditableRoles, OrgId, Parks, Id } = JSON.parse(
          user.data
        );

        const parks = Parks.map((park: IPark) => park.Id);

        dispatch({ type: 'updateUserRoles', payload: UserRoles });
        dispatch({ type: 'updateEditRoles', payload: EditableRoles });
        dispatch({ type: 'updateUserOrgId', payload: OrgId });
        dispatch({ type: 'updateUserParkAccess', payload: parks });
        dispatch({ type: 'updateUserId', payload: Id });
      });
    }
  }, [context.token]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <List>
      {hasAuthorization(
        state.userRoles,
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
      ) ? (
        <A href={URLS.OVERVIEW}>
          <ListItem /*button*/>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={'Analytics'} />
          </ListItem>
        </A>
      ) : null}

      {hasAuthorization(state.userRoles, [1, 5, 7]) ? (
        <A href={URLS.DISPATCH}>
          <ListItem /*button*/>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={'Dispatch'} />
          </ListItem>
        </A>
      ) : null}

      {hasAuthorization(state.userRoles, [1, 2, 12, 13, 14]) ? (
        <A href={URLS.EXPLORER}>
          <ListItem /*button*/>
            <ListItemIcon>
              <ExploreIcon />
            </ListItemIcon>
            <ListItemText primary={'Explorer Quest'} />
          </ListItem>
        </A>
      ) : null}

      {hasAuthorization(state.userRoles, [1]) ? (
        <A href={URLS.DEVICES}>
          <ListItem /*button*/>
            <ListItemIcon>
              <WatchIcon />
            </ListItemIcon>
            <ListItemText primary={'Device Management'} />
          </ListItem>
        </A>
      ) : null}

      {hasAuthorization(state.userRoles, [1]) ? (
        <A href={URLS.USERS}>
          <ListItem /*button*/>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary={'User Management'} />
          </ListItem>
        </A>
      ) : null}

      {hasAuthorization(state.userRoles, [1, 18, 19]) && <Divider />}

      {hasAuthorization(state.userRoles, [1]) ? (
        <A href={URLS.TRACKER}>
          <ListItem /*button*/>
            <ListItemIcon>
              <TrackChangesIcon />
            </ListItemIcon>
            <ListItemText primary={'Tracker'} />
          </ListItem>
        </A>
      ) : null}

      {hasAuthorization(state.userRoles, [1]) ? (
        <A href={URLS.TOOLS}>
          <ListItem /*button*/>
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={'TraKid Tools'} />
          </ListItem>
        </A>
      ) : null}

      {hasAuthorization(state.userRoles, [18, 19]) ? (
        <A href={URLS.MANUFACTURING}>
          <ListItem /*button*/>
            <ListItemIcon>
              <SdCardIcon />
            </ListItemIcon>
            <ListItemText primary={'Manufacturing'} />
          </ListItem>
        </A>
      ) : null}

      {props.isOpen ? (
        <ListItem /*button*/ aria-label='drawer control'>
          <ListItemIcon onClick={props.handleClose}>
            <ChevronLeftIcon />
          </ListItemIcon>
          <ListItemText onClick={props.handleClose}>Close</ListItemText>
        </ListItem>
      ) : (
        <ListItem /*button*/ aria-label='drawer control'>
          <ListItemIcon onClick={props.handleOpen}>
            <ChevronRightIcon />
          </ListItemIcon>
          <ListItemText onClick={props.handleClose}>Close</ListItemText>
        </ListItem>
      )}
    </List>
  );
};

export default memo(SideNavList);
