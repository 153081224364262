import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import { getGoogleMapsApiKey } from '../../../../../config';
import ZoneMarker from './markers/ZoneMarker';

import {
  createMarker,
  createCircle,
  clearLastMarker,
  createExistingZoneMarkers,
  isLocationInExistingZone,
  locationBoundsChecker,
} from '../../../../../helpers/MapHelper';

const options = {
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  styles: [
    {
      stylers: [
        { saturation: -100 },
        { gamma: 0.8 },
        { lightness: 4 },
        { visibility: 'on' },
      ],
    },
  ],
};

function ZoneMap({
  clickCallback,
  mapCenter,
  locations,
  radius,
  lat,
  lng,
  currentLocation,
}) {
  const [activeMap, setActiveMap] = useState(null);
  const [activeMaps, setActiveMaps] = useState(null);

  const [centerPoint, setCenterPoint] = useState({});
  const [, setZoomLevel] = useState(18);
  const [zoneMarker, setZoneMarker] = useState({});
  const [prevZoneMarker, setPrevZoneMarker] = useState({});
  const [existingZoneMarkers, setExistingZoneMarkers] = useState([]);
  const [existingZoneCircles, setExistingZoneCircles] = useState([]);
  const [currentMarker, setCurrentMarker] = useState([]);

  const handleApiLoaded = (map, maps) => {
    const [markers] = createExistingZoneMarkers(map, locations);

    markers.forEach(marker => {
      marker.addListener('click', handleZoneClick);
      marker.setMap(map);
    });

    map.addListener('click', mapsMouseEvent => {
      handleClickEvent(mapsMouseEvent);
    });

    console.log('map', map);
    console.log('maps', maps);
    setActiveMap(map);
    setActiveMaps(maps);
  };

  const handleZoneClick = () => {
    console.log('in Zone');
  };

  const handleClickEvent = mapsMouseEvent => {
    if (clickCallback) {
      let marker = [];
      const result = {
        lat: parseFloat(mapsMouseEvent.latLng.lat().toFixed(7)),
        lng: parseFloat(mapsMouseEvent.latLng.lng().toFixed(7)),
      };

      const inExistingLocation = isLocationInExistingZone(
        result.lat,
        result.lng,
        existingZoneCircles
      );

      console.log('active Map', activeMap);
      console.log('active maps', activeMaps);

      if (!inExistingLocation) {
        clearLastMarker(prevZoneMarker);

        const m = createMarker(activeMap, zoneMarker.lat, zoneMarker.lng);

        m.setIcon({
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 5,
          strokeWeight: 3,
          strokeColor: 'black',
          fillColor: 'black',
        });

        // Radius bug
        let r = radius;
        // if (!(r > 0)) {
        //   r = 20;
        // }

        const circle = createCircle(activeMap, r, currentLocation.Color);
        circle.bindTo('center', m, 'position');
        marker.push(circle);
        setPrevZoneMarker({ marker: m, circle: circle });

        setCurrentMarker(marker);
        clickCallback(result);
      }
    }
  };

  // ONLOAD
  useEffect(() => {
    setCenterPoint(mapCenter);
    setZoomLevel(17);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const result = {
      lat: parseFloat(lat.toFixed(7)),
      lng: parseFloat(lng.toFixed(7)),
    };
    const inExistingLocation = isLocationInExistingZone(
      result.lat,
      result.lng,
      existingZoneCircles
    );
    locationBoundsChecker(result, radius, locations);
    if (!inExistingLocation) {
      clickCallback(result);
    }
  }, [lat, lng]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentLocation) {
      clearLastMarker(prevZoneMarker);

      const m = createMarker(activeMap, zoneMarker.lat, zoneMarker.lng);

      m.setIcon({
        path: window.google.maps.SymbolPath.CIRCLE,
        scale: 5,
        strokeWeight: 3,
        strokeColor: 'black',
        fillColor: 'black',
      });

      // Radius bug
      let r = radius;
      if (!(r > 0)) {
        r = 20;
      }

      const circle = createCircle(activeMap, r, currentLocation.Color);
      circle.bindTo('center', m, 'position');
      setPrevZoneMarker({ marker: m, circle: circle });
    }
  }, [currentLocation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: getGoogleMapsApiKey() }}
        options={options}
        defaultCenter={mapCenter}
        center={centerPoint}
        defaultZoom={18}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
      >
        {/* {currentMarker.map(marker => {
          return <ZoneMarker key={marker} />;
        })} */}
        {/* <ZoneMarker
          lat={mapCenter.lat}
          lng={mapCenter.lng}
          radius={radius}
          color={'orange'}
          text='My Marker'
        /> */}
      </GoogleMapReact>
    </div>
  );
}

ZoneMap.propTypes = {
  clickCallback: PropTypes.func.isRequired,
  mapCenter: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  radius: PropTypes.number,
  lat: PropTypes.number,
  lng: PropTypes.number,
  currentLocation: PropTypes.object,
};

export default ZoneMap;
