import { withStyles } from '@mui/styles';
import { Button } from '@mui/material';

const SuccessButton = withStyles(() => ({
  root: {
    color: '#000',
    backgroundColor: '#a7e163',
    '&:hover': {
      backgroundColor: '#a7e163',
    },
    '&:disabled': {
      backgroundColor: '#bdbdbd',
      color: '#424242',
    },
  },
}))(Button);

export default SuccessButton;
