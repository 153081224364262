import { getBaseUrl, getAPIVersion, getSecretKey } from '../config';

const version = getAPIVersion();
const sk = getSecretKey();
const path = `/api/${version}/trackers/`;

export function getTrackers(id, callback) {
  fetch(getBaseUrl() + path + 'gettrackers', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      count: 0,
      accountId: id,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function getTrackerEntries(id, accountId, count, context, callback) {
  fetch(getBaseUrl() + path + 'gettrackerentries', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({
      count: count,
      trackerId: id,
      accountId: accountId,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function addTracker(tracker, callback) {
  fetch(getBaseUrl() + path + 'addtrackertoaccount', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      trackerId: tracker.TrackerId,
      accountId: tracker.AccountId,
      nickname: tracker.Nickname,
      gender: parseInt(tracker.Gender),
      specialNeeds: tracker.SpecialNeeds,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function updateTracker(tracker, callback) {
  fetch(getBaseUrl() + path + 'updatetracker', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      trackerId: tracker.TrackerId,
      accountId: tracker.AccountId,
      nickname: tracker.Nickname,
      gender: parseInt(tracker.Gender),
      specialNeeds: tracker.SpecialNeeds,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function exchangeTracker(oldId, newId, accountId, callback) {
  fetch(getBaseUrl() + path + 'exchangetracker', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      oldTrackerId: oldId,
      newTrackerId: newId,
      accountId: accountId,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function addTrackerEntry(trackerId, battery, latitude, longitude) {
  fetch(getBaseUrl() + path + 'addtrackerentry', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id: trackerId,
      b: battery,
      la: latitude,
      lo: longitude,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {})
    .catch(console.log);
}

export function removeTracker(trackerId, accountId, callback) {
  fetch(getBaseUrl() + path + 'removetrackerfromaccount', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      trackerId: trackerId,
      accountId: accountId,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export function getLostTrackers(parkId, callback) {
  fetch(getBaseUrl() + path + 'getlosttrackers', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      parkId: parkId,
      s: sk,
    }),
  })
    .then(res => res.json())
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}
