import { ChangeEvent } from 'react';

import { Tutorial } from 'models/Tutorial';

import { TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';


interface Props {
  tutorial: Tutorial;
  onFormFieldUpdate: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}
const NavigateScreenForm = ({ tutorial, onFormFieldUpdate }: Props) => {
  return (
    <form>
      <Grid
        container
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='stretch'
      >
        <Grid size={6}>
          <TextField
            id='title-text-1'
            label={`Title for ${
              tutorial.StateText1 === '' ? 'Navigate' : tutorial.StateText1
            } Screen`}
            onChange={onFormFieldUpdate}
            name='Title1'
            value={tutorial.Title1}
            fullWidth
            variant='outlined'
          />
        </Grid>

        <Grid size={6}>
          <TextField
            id='description-text-1'
            label={`Description for ${
              tutorial.StateText1 === '' ? 'Navigate' : tutorial.StateText1
            } Screen`}
            onChange={onFormFieldUpdate}
            name='Description1'
            value={tutorial.Description1}
            fullWidth
            variant='outlined'
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default NavigateScreenForm;
