import PropTypes from 'prop-types';

import { makeStyles } from '@mui/styles'
import {
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CardHeader,
  Avatar,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  media: {
    height: 240,
  },
}));

function RewardCard({
  imgSrc,
  tierName,
  completionMessage,
  nextStepsMessage,
  onBadgeImageUpload,
  onBadgeEdit,
  rewardTier,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label='recipe' className={classes.avatar}>
            {rewardTier.TierPriority}
          </Avatar>
        }
        title={tierName}
        subheader={`Points ${rewardTier.RequiredPointsPct}% | Challenges ${rewardTier.RequiredChallengesPct}%`}
      />
      {rewardTier.Image ? (
        <CardMedia
          className={classes.media}
          component='img'
          src={rewardTier.Image}
        />
      ) : (
        <CardMedia className={classes.media} component='img' src={imgSrc} />
      )}
      <CardContent>
        <Typography variant='body2' color='textSecondary' component='p'>
          <em>
            <strong>Completion Message</strong>
          </em>
          :<br />
          {completionMessage}
        </Typography>
        <Typography variant='body2' color='textSecondary' component='p'>
          <em>
            <strong>Next Steps Message</strong>
          </em>
          :<br />
          {nextStepsMessage}
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          size='small'
          color='primary'
          component='label'
          startIcon={<CloudUploadIcon />}
        >
          Upload Image
          <input
            type='file'
            accept='.jpeg, .png, .jpg'
            hidden
            onChange={e => onBadgeImageUpload(e, rewardTier)}
          />
        </Button>
        <Button
          size='small'
          color='primary'
          startIcon={<EditIcon />}
          onClick={e => onBadgeEdit(e, rewardTier)}
        >
          Edit Reward
        </Button>
      </CardActions>
    </Card>
  );
}

RewardCard.propTypes = {
  imgSrc: PropTypes.string,
  tierName: PropTypes.string,
  completionMessage: PropTypes.string,
  nextStepsMessage: PropTypes.string,
  onBadgeImageUpload: PropTypes.func,
};

export default RewardCard;
