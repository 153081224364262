import axios from 'axios';

import { getBaseUrl, getAPIVersion } from 'config';

const version = getAPIVersion(true);
const path = `/api/${version}/orgs/`;

axios.defaults.headers.post['Content-Type'] = 'application/json';

export function getOrg(context, id) {
  return axios({
    method: 'post',
    url: getBaseUrl() + path + 'getorg',
    headers: { Authorization: `Bearer ${context.token}` },
    data: {
      id,
    },
  });
}

// TODO future remove.
export function getOrgs(context, callback) {
  fetch(getBaseUrl() + '/api/v2/orgs/getorgs', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({}),
  })
    .then(res => {
      if (res.status === 401) {
        context.logout();
      } else {
        return res.json();
      }
    })
    .then(data => {
      callback(data);
    })
    .catch(console.log);
}

export async function getOrganizations(context) {
  const response = await fetch(getBaseUrl() + path + 'getorgs', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + context.token,
    },
    body: JSON.stringify({}),
  });

  if (response.ok) {
    try {
      return response.json();
    } catch (error) {
      console.error(error);
    }
  }
}
