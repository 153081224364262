import { ChangeEvent } from 'react';

import { Tutorial } from 'models/Tutorial';

import { TextField, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface Props {
  tutorial: Tutorial;
  onFormFieldUpdate: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onCompassImageUpdate: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
}
const EngageScreenForm = ({
  tutorial,
  onFormFieldUpdate,
  onCompassImageUpdate,
}: Props) => {
  return (
    <form>
      <Grid
        container
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='stretch'
      >
        <Grid size={12}>
          <TextField
            id='title-text-3'
            label={`Title for ${
              tutorial.StateText3 === '' ? 'Help' : tutorial.StateText3
            } Screen`}
            onChange={onFormFieldUpdate}
            name='Title3'
            value={tutorial.Title3}
            fullWidth
            variant='outlined'
          />
        </Grid>

        <Grid size={12}>
          <TextField
            id='description-text-3'
            label={`Description for ${
              tutorial.StateText3 === '' ? 'Help' : tutorial.StateText3
            } Screen`}
            onChange={onFormFieldUpdate}
            name='Description3'
            value={tutorial.Description3}
            fullWidth
            variant='outlined'
            multiline
            rows={4}
          />
        </Grid>

        <Grid size={12}>
          <Button
            variant='contained'
            color='primary'
            component='label'
            startIcon={<CloudUploadIcon />}
          >
            Select Image
            <input
              type='file'
              accept='.jpeg, .png, .jpg'
              hidden
              onChange={onCompassImageUpdate}
            />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EngageScreenForm;
