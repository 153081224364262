import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import Context from './auth';
import { authenticate } from 'services/Auth.Service';
import { UserContext } from 'context/UserContext';

function AuthContextProvider({ adminProps, children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const { state, dispatch } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [attempts, setAttempts] = useState(0);

  const login = (email, password) => {
    setLoading(true);

    authenticate(email, password)
      .then(response => {
        if (response.status === 200) {
          // console.log(response);
          const { Token, Id, OrgId, Parks } = { ...response.data };

          const parks = Parks.map(park => park.Id);

          // dispatch({ type: 'updateUserRoles', payload: UserRoles });
          // dispatch({ type: 'updateEditRoles', payload: EditableRoles });
          dispatch({ type: 'updateUserOrgId', payload: OrgId });
          dispatch({ type: 'updateUserParkAccess', payload: parks });
          dispatch({ type: 'updateUserId', payload: Id });

          setCookie('token', Token);
          setAttempts(attempts + 1);
        }
      })
      .catch(error => {
        console.error(error);
        setError('Error logging in.');
        setAttempts(attempts + 1);
        setLoading(false);
      });

    return error;
  };

  const logout = () => {
    removeCookie('token');
  };

  return (
    <Context.Provider
      value={{
        token: cookies.token,
        error,
        loading,
        login,
        logout: logout,
        tbProps: adminProps,
        attempts,
      }}
    >
      {children}
    </Context.Provider>
  );
}

AuthContextProvider.propTypes = {
  adminProps: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default AuthContextProvider;
