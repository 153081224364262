import { createContext, useReducer } from 'react';

const UserContext = createContext();

const initialState = {
  loading: false,
  orgId: null,
  userId: null,
  userRoles: [],
  editableRoles: [],
  accessToParks: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateUserId':
      return { ...state, userId: action.payload };
    case 'updateUserRoles':
      return { ...state, userRoles: action.payload };
    case 'updateEditRoles':
      return { ...state, editableRoles: action.payload };
    case 'updateUserOrgId':
      return { ...state, orgId: action.payload };
    case 'updateUserParkAccess':
      return { ...state, accessToParks: action.payload };
    default:
      return { ...state };
  }
};

function UserContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

const UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer };
