import Location from './Location';

interface Option {
  Id: number | null;
  Position: number;
  OptionText: string;
  ResponseText: string;
  IsAnswer: boolean | null;
}

interface Question {
  Id: number | null;
  AnswerPosition: number | null;
  Options: Option[];
}

interface Activity {
  Id: number | null;
  ActivityType: number;
  HeaderText: string;
  QuestionText: string;
  PlaceholderText: string;
  Answer: string;
  ResponseWhenCorrect: string;
  ResponseWhenWrong: string;
  Question: Question | null;
}

export class Point extends Location {
  Id: number | null;
  Proximity: number;
  IsHidden: boolean;
  Image: string;
  ImageId: number | null;
  Activity: Activity;

  constructor(
    id: number | null,
    zoneId: number | null,
    shortName: string,
    longName: string,
    description: string,
    latitude: number,
    longitude: number,
    radius: number,
    color: string,
    proximity: number,
    imageId: number | null,
    image: string,
    isHidden: boolean,
    isActive: boolean,
    activity: Activity
  ) {
    super(
      zoneId,
      shortName,
      longName,
      description,
      latitude,
      longitude,
      radius,
      color,
      isActive
    );
    this.Id = id;
    this.Proximity = proximity;
    this.IsHidden = isHidden;
    this.Image = image;
    this.ImageId = imageId;
    this.Activity = activity;
  }
}
