import { IActivity } from 'models/IActivity';

import { Grid, TextField } from '@mui/material';

interface IProps {
  activity: IActivity;
  handleChange: (e: EventTarget, b: boolean) => void;
}
function ConfirmationActivityForm(props: IProps) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id='waypoint-response-when-correct'
            label='Response Text'
            name='ResponseWhenCorrect'
            helperText=''
            variant='outlined'
            value={props.activity.ResponseWhenCorrect}
            onChange={e => props.handleChange(e.target, true)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='waypoint-answer'
            label='Button Text'
            name='Answer'
            helperText=''
            variant='outlined'
            value={props.activity.Answer}
            onChange={e => props.handleChange(e.target, true)}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ConfirmationActivityForm;
