export default class QuestionOption {
  Id: number | null;
  Position: number;
  OptionText: string;
  ResponseText: string;
  IsAnswer: boolean;

  constructor(
    id: number,
    position: number,
    optionText: string,
    responseText: string,
    isAnswer: boolean
  ) {
    this.Id = id;
    this.Position = position;
    this.OptionText = optionText;
    this.ResponseText = responseText;
    this.IsAnswer = isAnswer;
  }
}
