class Park {
  ParkId: number;
  Name: string;
  Address: string;
  City: string;
  State: string;
  Zip: string;
  Latitude: number;
  Longitude: number;
  Zoom: number;

  constructor(
    parkId: number,
    name: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    latitude: number,
    longitude: number,
    zoom: number
  ) {
    this.ParkId = parkId;
    this.Name = name;
    this.Address = address;
    this.City = city;
    this.State = state;
    this.Zip = zip;
    this.Latitude = latitude;
    this.Longitude = longitude;
    this.Zoom = zoom;
  }
}

export default Park;
