import { useNavigate } from 'react-router-dom';

export const hasAuthorization = (
  userRoles: number[],
  neededRoles: number[]
): boolean => {
  let allowed = false;

  neededRoles.forEach(role => {
    if (userRoles.indexOf(role) > -1) {
      allowed = true;
    }
  });

  return allowed;
};

export const useCheckAuthorization = (
  userRoles: number[],
  neededRoles: number[]
) => {
  const navigate = useNavigate();
  if (!hasAuthorization(userRoles, neededRoles)) {
    navigate('/not-authorized');
  }
};