export function getBaseUrl() {
  const key = 'https://api-dev.trakid.com';
  if (process.env.REACT_APP_LOCAL_URL) {
    return process.env.REACT_APP_LOCAL_URL;
  }

  return key;
}

export function getGoogleMapsApiKey() {
  const key = 'AIzaSyCsE6ZB4RIMMKoPAv1LivUzPfMAtvmWUqc';
  if (process.env.REACT_APP_API_KEY) {
    return process.env.REACT_APP_API_KEY;
  }

  return key;
}

export function getEnvironment() {
  const key = 'PRODUCTION';
  if (process.env.REACT_APP_ENVIRONMENT) {
    return process.env.REACT_APP_ENVIRONMENT;
  }

  return key;
}

export function getAPIVersion(next = false) {
  if (next) {
    return 'v4';
  }

  return 'v3';
}

export function getSecretKey() {
  return 'KhZhl14P#1';
}

export function getMUIXProlicenseKey() {
  const key = '';
  if (process.env.REACT_APP_MUIX_KEY) {
    return process.env.REACT_APP_MUIX_KEY;
  }

  return key;
}
